import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';

import loginSlice from './loginSlice';
import messageSnackbarSlice from './messageSnackbarSlice';
import thunk from 'redux-thunk';
import sidebarSlice from './sidebarSlice';
import parentIdSlice from './parentIdSlice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['sidebar'] // blacklist option which can be used to specify which parts of your state you do not want to persist. Now ONLY sidebar will not save across refreshes since we want the initial state to be used again
};

const appReducer = combineReducers({
    login: loginSlice,
    messageSnackbar: messageSnackbarSlice,
    sidebar: sidebarSlice,
    parentId: parentIdSlice,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'login/clearStoreLogout') {
        localStorage.removeItem('persist:root');
        state = {} as RootState;
    }
    return appReducer(state, action);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});
export default store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;