import React, { useState } from 'react';
import { Meta } from '../MetaDetail';
import { BEditableTable, BEditableTableColumn, BOption } from 'mui-bueno';
import { FormikHelpers } from 'formik';

import { useDispatch } from 'react-redux';
import { setPendingUpdate } from '../../../store/sidebarSlice';
import { useNavigate } from 'react-router-dom';
import { getAllTags } from '../../../service/Management/tags';
import { Autocomplete, Button, TextField } from '@mui/material';
import { TableLabel } from '../../../@types';
import { cloneTable, getTableLabels } from '../../../service/Meta/tableMeta';

const childTypes = {
    project: 'Database',
    database: 'Schema',
    schema: 'Table',
    table: 'Column',
    column: 'Constraint'
}

interface MetaListProps {
    id?: number;
    rows: Meta[];
    updateChild: (childMeta: Meta) => void;
    createChild: (childMeta: Meta) => void;
    deleteChild: (childId: number) => void;
    level: 'project' | 'database' | 'schema' | 'table' | 'column';
}
const MetaList = (props: MetaListProps) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [tables, setTables] = React.useState<TableLabel[]>([]);
    const [selectedTable, setSelectedTable] = React.useState<TableLabel>({
        tableId: undefined,
        label: ''
    });

    React.useEffect(() => {
        getTableLabels().then(res => {
            const tables = res.data.filter(table => table.tableId != props.id)
            setTables(tables);
        }).catch(err => {
            console.error(err);
        });
    }, [props.id])

    let columnKeys: string[];
    let initialValues: Meta = {
        id: 0,
        name: '',
        description: '',
        tagIds: []
    };

    const [tagOptions, setTagOptions] = useState<BOption<string>[]>([]);

    React.useEffect(() => {
        getAllTags().then(res => {
            if (res.data.length == 0) setTagOptions([]);
            else setTagOptions(res.data.map(tag => ({
                value: `${tag.id}`,
                label: tag.name
            })))
        }).catch(err => {
            console.error(err);
        });
    }, []);

    //select list of columns based on level
    if (props.level === 'database' || props.level === 'schema') {
        columnKeys = [
            'name',
            'description',
        ];
        initialValues = {
            id: 0,
            name: '',
            description: '',
            tagIds: []
        }
    }
    else if (props.level === 'project') {
        columnKeys = [
            'name',
            'description',
            'type',
            'url'
        ];
        initialValues = {
            id: 0,
            name: '',
            description: '',
            type: '',
            url: '',
            tagIds: []
        }
    }
    else { //table level
        columnKeys = [
            'name',
            'dataType',
            'description',
            'columnLength'
        ];
        initialValues = {
            id: 0,
            name: '',
            description: '',
            dataType: '',
            columnLength: '' as any,
            tagIds: []
        }
    }

    /**
     * update edited attribute of record and pass updated record to parent
     * @param record 
     * @param val 
     * @param key 
     */
    const onCellEdit = (record: Meta, val: string | number | boolean | number[] | string[], key: string) => {
        console.log('editing cell!')
        record = { ...record, [key]: val };
        props.updateChild(record);
        dispatch(setPendingUpdate());
    };

    const columns: BEditableTableColumn<Meta>[] = columnKeys.map(key => ({
        valueKey: key,
        label: key === 'name'
            ? `${childTypes[props.level]} Name`
            : key.charAt(0).toUpperCase() + key.slice(1),
        sortable: true,
        required: true,
        width: '20%',
        onCellEdit: (record: Meta, val: string) => onCellEdit(record, val, key)
    }));

    columns.push({
        valueKey: 'tagIds',
        required: false,
        width: '20%',
        type: 'multiselect',
        bOptions: tagOptions,
        onCellEdit: (record: Meta, val: number[]) => onCellEdit(record, val, 'tagIds')
    });


    /**
     * Call to parent to createChild
     * @param record 
     * @param param1 
     */
    const onSubmit = (record: Meta, { resetForm }: FormikHelpers<Meta>) => {
        resetForm();
        props.createChild(record);
        dispatch(setPendingUpdate());
    };

    /**
     * Call to parent to delete child
     * @param record 
     */
    const onConfirmDelete = (record: Meta) => {
        props.deleteChild(Number(record.id));
        dispatch(setPendingUpdate());
    };

    const rowDeleteProps = (record: Meta) => ({
        objectName: record.name,
        objectType: 'Meta',
        onConfirm: () => onConfirmDelete(record)
    });

    const onRowNavigate = (record: Meta) => {
        const level = childTypes[props.level].toLowerCase();
        navigate(`../${level}/${record.id}`)
    };

    const divStyle = { padding: '10px 0px' };

    const handleMoveTableChange = (value: TableLabel) => {
        setSelectedTable(value);
    }

    const handleMoveTableClick = () => {
        if (props.id && selectedTable) {
            cloneTable(selectedTable.tableId as number, props.id).then(() => {
                navigate(`/table/${props.id}`);
            }).catch(err => {
                window.alert(err);
            })
        }
    }

    return (
        <div style={{ padding: '20px' }}>
            <div style={divStyle}>
                <div>
                    <BEditableTable
                        id='1'
                        data={props.rows}
                        columns={columns}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        rowDeleteProps={rowDeleteProps}
                        onRowNavigate={onRowNavigate}
                    />
                </div>
            </div>
            {props.level == 'table' &&
                <>
                    <Autocomplete
                        sx={{ width: '40%', marginTop: '10px' }}
                        options={tables}
                        getOptionLabel={(option: TableLabel) => option.label}
                        value={selectedTable}
                        onChange={(event, value) => handleMoveTableChange(value as TableLabel)}
                        renderInput={(params) => <TextField {...params} label='Choose a table to copy' variant='outlined' />}
                    />
                    <Button variant='contained' style={{ marginTop: '10px' }} onClick={handleMoveTableClick}> Copy</Button>
                </>
            }
        </div>
    );
};

export default MetaList;
