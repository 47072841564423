import useLoggedInUser from '../hooks/useLoggedInUser';

export interface Feature {
    name: string;
    route: string;
    icon?: React.ReactNode;
    children?: Feature[];
}

export interface NavItems {
    [key: string]: Feature[];
}

export const getNavItems = (route?: string) => {

    const navItems: NavItems = {
        protected: [
            {
                name: 'View Comparison',
                route: '/comparison-view'
            },
        ],
        account: [
            {
                name: 'Profile',
                route: `/user/${useLoggedInUser().id}`
            },
            {
                name: 'Users',
                route: '/user'
            },
            {
                name: 'Roles',
                route: '/role'
            },
            {
                name: 'Tags',
                route: '/tag'
            },
        ],
        management: [],
        settings: [],
        optional: []
    };

    if (route) {
        // Sample way to add custom header buttons:
        if (route == '/dashboard') {
            navItems.optional.push({
                name: 'List',
                // This route should be whatever the path for list view is
                route: '/list'
            })
        }
        if (route == '/list') {
            navItems.optional.push({
                name: 'Dashboard',
                route: '/dashboard'
            })
        }
    }

    return navItems;
};

export default getNavItems;
