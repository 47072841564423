import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import jwt_decode from 'jwt-decode';
import { AxiosError } from 'axios';

import { DecodedToken } from '../../@types';
import axiosInstance from '../../service/Access/axiosInstance';
import { requestToken } from '../../service/Access/authentication';
import {
    clearStoreLogout,
    refreshAccessToken
} from '../../store/loginSlice';
import { showErrorSnackbar } from '../../store/messageSnackbarSlice';
import {
    clearTokens,
    getRefreshToken,
    setTokens
} from '../../features/Access/Login/localStorageUtil';
import { RootState } from '../../store/store';

export const requestTokenCallback = (
    refreshToken: string | null,
    accessToken: string | null,
    dispatcher: React.Dispatch<any>
) => {
    requestToken(refreshToken, accessToken)
        .then(async (res) => {
            // Set the new access and refresh tokens
            const newAccessToken = res.data.accessToken;
            const newRefreshToken = res.data.refreshToken;
            axiosInstance.defaults.headers.common.Authorization =
                'Bearer ' + newAccessToken;
            setTokens(newAccessToken, newRefreshToken);
            dispatcher(refreshAccessToken(newAccessToken));
        })
        .catch((err: AxiosError) => {
            axiosInstance.defaults.headers.common.Authorization = '';
            clearTokens();
            dispatcher(clearStoreLogout());

            if (err.response) {
                dispatcher(
                    showErrorSnackbar(
                        'Your session has ended. Please log in again.'
                    )
                );
            }
        });
};
/**
 * This is to refresh accessToken before it's expired.
 * As long as the UI retains the accessToken, it will try to renew before its expiration.
 * When the session is logged out or timed out, the renewal will stop.
 */
const TokenRefresher: React.FC = () => {
    const SERVICE_TOKEN_REFRESH_IN_SECONDS = 5 * 60; // refresh at 5 minutes before expiration

    const accessToken = useSelector<RootState, string>(
        (store) => store.login.accessToken
    );

    const dispatch = useDispatch();

    const [timer, setTimer] = React.useState<NodeJS.Timeout>();

    const resetTimer = () => {
        if (timer) {
            clearTimeout(timer);
            setTimer(undefined);
        }
    };

    React.useEffect(() => {
        if (accessToken && accessToken !== '') {
            const now = +new Date();
            const decodedToken: DecodedToken = jwt_decode(accessToken);
            const millSecondsToExpire = decodedToken.exp * 1000 - now;
            const millSecondsToRefresh =
                millSecondsToExpire - SERVICE_TOKEN_REFRESH_IN_SECONDS * 1000;
            resetTimer();

            const refreshToken = getRefreshToken();
            const requestTimer = setTimeout(() => {
                requestTokenCallback(refreshToken, accessToken, dispatch);
            }, millSecondsToRefresh);
            setTimer(requestTimer);
        } else {
            //logged out
            resetTimer();
        }
    }, [accessToken]);

    // Intentionally has null return because we don't need to render any TSX
    return null;
};

export default TokenRefresher;
