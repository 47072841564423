import * as React from 'react';
import MetaDetail, { Meta } from '../../common/MetaDetail/MetaDetail';

import { DatabaseMeta, ProjectMeta } from '../../@types';
import { useNavigate, useParams } from 'react-router-dom';
import {
    createProjectMeta, deleteProjectMeta,
    getProjectMeta,
    updateProjectMeta
} from '../../service/Meta/projectMeta';

import {
    createDatabaseMeta, deleteDatabaseMeta,
    getAllByProjectId,
    updateDatabaseMeta
} from '../../service/Meta/databaseMeta';

interface RouteParams extends Record<string, string> {
    projectId: string; // new or id
}
const initialDataState: ProjectMeta = {
    id: undefined,
    name: '',
    description: ''
}

const ProjectMetaDetail = () => {
    const navigate = useNavigate();
    const [children, setChildren] = React.useState<Meta[]>([])
    const [data, setData] = React.useState<ProjectMeta>(initialDataState)


    const onCreate = async () => {

        try {
            const res = await createProjectMeta({ name: data.name, description: data.description, tagIds: data.tagIds });

            setMetaId(res.data.id);
            navigate(`../project/${res.data.id}`);

        } catch (error) {
            console.error('Error while creating:', error);
        }

    }
    const onDelete = () => {
        try {
            deleteProjectMeta(Number(metaId));
            //navigate back to project list
            navigate('../project');

        } catch (error) {
            console.error('error during deletion')
            alert(error);
        }
    }


    const onUpdate = async () => {
        try {
            const res = await updateProjectMeta({
                id: metaId,
                name: data.name,
                description: data.description,
                tagIds: data.tagIds
            });

            setMetaId(res.data.id);
            navigate(`../project/${res.data.id}`);

        } catch (error) {

            alert('An error occurred while updating. Please try again.');
        }

    }


    /**
     * Adds a child to the editable list of children
     * Page rerenders including new child
     * @param child the child to add
     */
    const onCreateChild = async (child: Meta) => {
        const dataBase: DatabaseMeta = child as DatabaseMeta;
        dataBase.id = undefined;
        dataBase.projectId = Number(metaId);
        try {
            await createDatabaseMeta(dataBase);
            //TODO maybe this should not refetch all the data?
            getAllByProjectId(Number(metaId)).then((res) => {
                const databases: DatabaseMeta[] = res.data;
                setChildren(databases as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while creating child database')
        }

    }


    const onUpdateChild = async (child: Meta) => {

        try {
            await updateDatabaseMeta(child as DatabaseMeta);
            getAllByProjectId(Number(metaId)).then((res) => {
                const databases: DatabaseMeta[] = res.data;
                setChildren(databases as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while updating child database')
        }
    }

    const onDeleteChild = async (childId: number) => {
        try {

            await deleteDatabaseMeta(Number(childId));
            getAllByProjectId(Number(metaId)).then((res) => {
                const databases: DatabaseMeta[] = res.data;
                setChildren(databases as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while deleting child database')
        }
    }

    //get the id of the current project from the url
    const { projectId } = useParams<RouteParams>();
    //This will become a state that allows the data prop to be populated
    const [metaId, setMetaId] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (projectId === 'new') {
            setMetaId(undefined);
            setChildren([]);
            setData(initialDataState);
        }
        else {
            setMetaId(Number(projectId));
        }
    }, [projectId])

    //Populate the page with the specified project information
    React.useEffect(() => {
        if (metaId === undefined) {
            setData(initialDataState);
            setChildren([]);
        }
        else {
            getProjectMeta(Number(metaId)).then((res) => {
                setData(res.data)
            })
            getAllByProjectId(Number(metaId)).then((res) => {
                const databases: DatabaseMeta[] = res.data;
                const rowData: Meta[] = [];
                for (let i = 0; i < databases.length; i++) {
                    const curr: DatabaseMeta = databases[i];
                    rowData.push(curr as Meta);
                }
                setChildren(rowData)
            })
        }

    }, [metaId]);


    return (
        <div>

            {/* eslint-disable-next-line react/no-children-prop */}
            <MetaDetail id={String(projectId)} level="project"
                onCreate={onCreate} onDelete={onDelete} setData={setData} data={data as Meta} onUpdate={onUpdate} childrenData={children} updateChild={onUpdateChild}
                deleteChild={onDeleteChild}
                createChild={onCreateChild} />

        </div>
    );
};

export default ProjectMetaDetail;
