import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';
import { TableMeta, PagingResponse, TableLabel } from '../../@types';

/** 
 * Create TableMeta
 */
export const createTableMeta = (tableMeta: TableMeta) =>
    axiosInstance.post<TableMeta>('/api/table', tableMeta).catch(handleError);

/** 
 * Update TableMeta
 */
export const updateTableMeta = (tableMeta: TableMeta) =>
    axiosInstance.put<TableMeta>('/api/table', tableMeta).catch(handleError);

/** 
 * Get by id TableMeta
 */
export const getTableMeta = (id: number) =>
    axiosInstance.get<TableMeta>(`/api/table/${id}`).catch(handleError);

/** 
* Delete by id TableMeta
*/
export const deleteTableMeta = (id: number) =>
    axiosInstance.delete<TableMeta>(`/api/table/${id}`).catch(handleError);

/** 
* schema Page TableMeta
*/
export const getTablePage = (page: number, size: number, sortFld: string) =>
    axiosInstance.get<PagingResponse<TableMeta>>(`/api/table/page?page=${page}&size=${size}&sortFld=${sortFld}`).catch(handleError);

/** 
* Get all Table by database id
*/
export const getAllBySchemaId = (id: number) =>
    axiosInstance.get<TableMeta[]>(`/api/table/schema/${id}`).catch(handleError);

/**
 * Get all tables and their custom labels
 */
export const getTableLabels = () =>
    axiosInstance.get<TableLabel[]>('/api/table/labels').catch(handleError);

/**
 * Clone table (move columns from one table to another)
 */
export const cloneTable = (fromId: number, toId: number) =>
    axiosInstance.post(`/api/table/clone?from=${fromId}&to=${toId}`).catch(handleError);