import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ColumnMeta, DatabaseMeta, ProjectMeta, SchemaMeta, TableMeta } from '../../@types';
import { TreeItem } from '@mui/lab';
import { getAllByProjectId } from '../../service/Meta/databaseMeta';
import { getAllByDatabaseId } from '../../service/Meta/schemaMeta';
import { getAllBySchemaId } from '../../service/Meta/tableMeta';
import { getAllByTableId } from '../../service/Meta/columnMeta';

interface Props {
    projects: ProjectMeta[],
    type: 'SOURCE' | 'DESTINATION',
    editMode: string,
    expandedNames: string[],
    chooseSelectedColumn: (selectedColumn: ColumnMeta) => void,
    transformationColumnNames: string[]
    onLegacySelection: (selected: ColumnMeta[]) => void, // Pass in the functions
    onModernSelection: (selected: ColumnMeta[]) => void,
    selectedLegacy: ColumnMeta[],
    selectedModern: ColumnMeta[],
}

export const COLUMN_LABEL = 'COLUMN-'
export const TABLE_LABEL = 'TABLE-'
export const SCHEMA_LABEL = 'SCEMA-'
export const DATABASE_LABEL = 'DATABASE-'
export const PROJECT_LABEL = 'PROJECT-'

const ComparisonViewTree: React.FC<Props> = (props) => {
    const chooseSelected = props.chooseSelectedColumn
    const editMode: string = props.editMode;
    const [expandedNames, setExpandedNames] = React.useState<string[]>(props.expandedNames);
    const columnsInTransformation: string[] = props.transformationColumnNames;

    const [selectedLegacy, setSelectedLegacy] = React.useState(props.selectedLegacy);
    const [selectedModern, setSelectedModern] = React.useState(props.selectedModern);

    const [projects, setProjects] = React.useState<ProjectMeta[]>([]);
    const [databases, setDatabases] = React.useState<DatabaseMeta[]>([]);
    const [schemas, setSchemas] = React.useState<SchemaMeta[]>([]);
    const [tables, setTables] = React.useState<TableMeta[]>([]);
    const [columns, setColumns] = React.useState<ColumnMeta[]>([]);

    React.useEffect(() => {
        setProjects(props.projects);
    }, [props.projects])

    /* UPDATES DATABASES */
    React.useEffect(() => {
        const getData = async () => { // Need an async function since Axios calls are async, must finish executing axios call before we do anything
            try {
                const databaseList: DatabaseMeta[] = [];
                for (const project of projects) { // Loop through all projects and add each of their databases to the whole list of databases set as a state variable
                    const response = await getAllByProjectId(project.id as number); // Cast to a number the id
                    // Use "await" for the promise to resolve and unwrap the values we actually want into an Axios response
                    const currList = response.data; // Grab the data from the Axios response
                    for (const database of currList) { // Go through the database list under the project and push each item
                        databaseList.push(database);
                    }
                }
                setDatabases(databaseList);
            } catch (error) {
                console.error('Error fetching projects: ', error);
            }
        }
        getData();
    }, [projects]) // Rely on projects changing to refresh, cascade effect down starting with projects

    /* UPDATES SCHEMAS */
    React.useEffect(() => {
        const getData = async () => {
            try {
                const schemaList: SchemaMeta[] = [];
                for (const database of databases) { 
                    const response = await getAllByDatabaseId(database.id as number);
                    const currList = response.data; 
                    for (const schema of currList) { 
                        schemaList.push(schema);
                    }
                }
                setSchemas(schemaList);
            } catch (error) {
                console.error('Error fetching projects: ', error);
            }
        }
        getData();
    }, [databases])

    /* UPDATES TABLES */
    React.useEffect(() => {
        const getData = async () => {
            try {
                const tableList: TableMeta[] = [];
                for (const schema of schemas) { 
                    const response = await getAllBySchemaId(schema.id as number);
                    const currList = response.data; 
                    for (const table of currList) { 
                        tableList.push(table);
                    }
                }
                setTables(tableList);
            } catch (error) {
                console.error('Error fetching projects: ', error);
            }
        }
        getData();
    }, [schemas])

    /* UPDATES COLUMNS */
    React.useEffect(() => {
        const getData = async () => {
            try {
                const columnList: ColumnMeta[] = [];
                for (const table of tables) { 
                    const response = await getAllByTableId(table.id as number);
                    const currList = response.data; 
                    for (const column of currList) { 
                        columnList.push(column);
                    }
                }
                setColumns(columnList);
            } catch (error) {
                console.error('Error fetching projects: ', error);
            }
        }
        getData();
    }, [tables])

    React.useEffect(() => {
        setSelectedModern(props.selectedModern);
    }, [props.selectedModern])

    React.useEffect(() => {
        setSelectedLegacy(props.selectedLegacy);
    }, [props.selectedLegacy])

    React.useEffect(() => {
        // console.log(selectedModern)
        props.onModernSelection(selectedModern)
    }, [selectedModern.length]) // when selectedModern changes from above, you're calling props.onModernSelection with the same selectedModern array. But at the time props.onModernSelection is called, the new value of selectedModern might not be available yet due to the asynchronous nature of state updates.

    React.useEffect(() => {
        props.onLegacySelection(selectedLegacy)
    }, [selectedLegacy.length])

    React.useEffect(() => {
        setExpandedNames(props.expandedNames);
    }, [props.expandedNames])

    const handleSelect = (selectedCol: ColumnMeta) => {
        chooseSelected(selectedCol);
    };
    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpandedNames(nodeIds);
    }

    const handleCheckboxToggle = (col: ColumnMeta) => {
        if (props.type === 'SOURCE') {
            if (!selectedLegacy.includes(col)) {
                setSelectedLegacy(selectedLegacy.concat(col))
            } else {
                setSelectedLegacy(selectedLegacy.filter(n => n.id !== col.id))
            }
        } else if (props.type === 'DESTINATION') {
            if (!selectedModern.includes(col)) {
                setSelectedModern(selectedModern.concat(col))
            } else {
                setSelectedModern(selectedModern.filter(n => n.id !== col.id))
            }
        }
    }

    return (
        <div className='treeView'>
            <h1>{props.type}</h1>
            <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                onNodeToggle={handleToggle}
                expanded={expandedNames}
                sx={{ height: 'calc(100% - 101px)', flexGrow: 1, overflow: 'auto', width: '100%' }}
            >
                {projects.map((project) => {

                    return (<TreeItem key={project.id} nodeId={PROJECT_LABEL + Number(project.id).toString()} label={project.name}>
                        {/** FILTER EACH SUBSEQUENT LAYER BY THE ID OF ITS PARENT. SO FOR EACH PARENT, ALL ITS CHILDREN ARE POPULATED */}
                        {databases.filter((database) => database.projectId == project.id).map((database) => {
                            return (<TreeItem nodeId={DATABASE_LABEL + Number(database.id).toString()} key={database.id} label={database.name}>
                                {schemas.filter((schema) => schema.databaseId == database.id).map((schema) => {
                                    return (
                                        <TreeItem nodeId={SCHEMA_LABEL + Number(schema.id).toString()} key={schema.id} label={schema.name}>
                                            {tables.filter((table) => table.schemaId == schema.id).map((table) => {
                                                return (
                                                    <TreeItem nodeId={TABLE_LABEL + Number(table.id).toString()} key={table.id} label={table.name}>
                                                        {columns.filter((column) => column.tableId == table.id).map((column) => {
                                                            return (

                                                                editMode === 'edit' ?
                                                                    <TreeItem nodeId={COLUMN_LABEL + Number(column.id).toString()} key={column.id} label={
                                                                        <FormControlLabel control={<Checkbox checked={selectedLegacy.some(current => current.id === column.id) || selectedModern.some(current => current.id === column.id)}
                                                                            onChange={() => handleCheckboxToggle(column)} />} label={column.name} />
                                                                    } />
                                                                    :
                                                                    columnsInTransformation.includes(COLUMN_LABEL + Number(column.id).toString()) ?

                                                                        <TreeItem nodeId={COLUMN_LABEL + Number(column.id).toString()} key={column.id} label={
                                                                            <div style={{ // Different styling if part of a transformation 
                                                                                backgroundColor: '#b0b6c0'

                                                                            }}
                                                                            onClick={() => handleSelect(column)}>
                                                                                {column.name}
                                                                            </div>
                                                                        }
                                                                        /> :
                                                                        <TreeItem nodeId={COLUMN_LABEL + Number(column.id).toString()} key={column.id} label={
                                                                            <div
                                                                                onClick={() => handleSelect(column)}>
                                                                                {column.name}
                                                                            </div>
                                                                        }
                                                                        />
                                                            )
                                                        })
                                                        }
                                                    </TreeItem>
                                                )
                                            })
                                            }
                                        </TreeItem>
                                    );
                                })
                                }
                            </TreeItem>)
                        })
                        }
                    </TreeItem>)
                })}
            </TreeView>
        </div>
    );
}

export default ComparisonViewTree;