import * as React from 'react';
import MetaDetail, { Meta } from '../../common/MetaDetail/MetaDetail';

import { DatabaseMeta, SchemaMeta } from '../../@types';
import { useNavigate, useParams } from 'react-router-dom';
import {
    createDatabaseMeta, deleteDatabaseMeta,
    getDatabaseMeta,
    updateDatabaseMeta
} from '../../service/Meta/databaseMeta';

import {
    createSchemaMeta, deleteSchemaMeta,
    getAllByDatabaseId,
    updateSchemaMeta
} from '../../service/Meta/schemaMeta';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ParentIdState } from '../../store/parentIdSlice';

interface RouteParams extends Record<string, string> {
    databaseId: string; // new or id
}

const initialDataState: DatabaseMeta = {
    id: undefined,
    name: '',
    description: '',
    projectId: 0,
    type: '',
    url: ''
}

const DatabaseMetaDetail = () => {
    const navigate = useNavigate();
    const { id: parentId } = useSelector<RootState, ParentIdState>(
        (store) => store.parentId
    );
    const [children, setChildren] = React.useState<Meta[]>([])
    const [data, setData] = React.useState<DatabaseMeta>(initialDataState)

    const onCreate = async () => {
        try {
            const res = await createDatabaseMeta({ name: data.name, description: data.description, projectId: parentId ?? 0, type: data.type, url: data.url, tagIds: data.tagIds });
            setMetaId(res.data.id);
            navigate(`../database/${res.data.id}`);

        } catch (error) {
            console.error('Error while creating:', error);
        }

    }
    const onDelete = () => {
        try {
            deleteDatabaseMeta(Number(metaId));
            //navigate to parent project on deletion
            navigate(`../project/${data.projectId}`);

        } catch (error) {
            console.error('error during deletion')
        }

    }


    const onUpdate = async () => {
        try {
            const res = await updateDatabaseMeta({
                id: metaId,
                name: data.name,
                description: data.description,
                projectId: data.projectId,
                type: data.type,
                url: data.url,
                tagIds: data.tagIds
            });

            setMetaId(res.data.id);
            navigate(`../database/${res.data.id}`);

        } catch (error) {
            console.error(error)
        }

    }


    /**
     * Adds a child to the editable list of children
     * Page rerenders including new child
     * @param child the child to add
     */
    const onCreateChild = async (child: Meta) => {
        const schema: SchemaMeta = child as SchemaMeta;
        schema.id = undefined;
        schema.databaseId = Number(metaId);
        try {
            await createSchemaMeta(schema);
            //TODO maybe this should not refetch all the data?
            getAllByDatabaseId(Number(metaId)).then((res) => {
                const schemas: SchemaMeta[] = res.data;
                setChildren(schemas as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while creating child database')
        }

    }


    const onUpdateChild = async (child: Meta) => {

        try {
            await updateSchemaMeta(child as SchemaMeta);
            getAllByDatabaseId(Number(metaId)).then((res) => {
                const schemas: SchemaMeta[] = res.data;
                setChildren(schemas as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while updating child schemas')
        }
    }

    const onDeleteChild = async (childId: number) => {
        try {

            await deleteSchemaMeta(Number(childId));
            getAllByDatabaseId(Number(metaId)).then((res) => {
                const schemas: SchemaMeta[] = res.data;
                setChildren(schemas as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while deleting child schema')
        }
    }

    //get the id of the current database from the url
    const { databaseId } = useParams<RouteParams>();
    //This will become a state that allows the data prop to be populated
    const [metaId, setMetaId] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (databaseId === 'new') {
            setMetaId(undefined);
            setChildren([]);
            setData(initialDataState)
        }
        else {
            setMetaId(Number(databaseId));
        }
    }, [databaseId])

    //Populate the page with the specified database information
    React.useEffect(() => {
        if (metaId === undefined) {
            setData(initialDataState)
            setChildren([]);
        }
        else {
            getDatabaseMeta(Number(metaId)).then((res) => {
                setData(res.data)
            })
            getAllByDatabaseId(Number(metaId)).then((res) => {
                const schemas: SchemaMeta[] = res.data;
                const rowData: Meta[] = [];
                for (let i = 0; i < schemas.length; i++) {
                    const curr: SchemaMeta = schemas[i];
                    rowData.push(curr as Meta);
                }
                setChildren(rowData)
            })
        }

    }, [metaId]);


    return (
        <div>
            {/* eslint-disable-next-line react/no-children-prop */}
            <MetaDetail id={String(databaseId)} level="database"
                onCreate={onCreate} onDelete={onDelete} setData={setData as React.Dispatch<React.SetStateAction<Meta>>} data={data as Meta} onUpdate={onUpdate} childrenData={children} updateChild={onUpdateChild}
                deleteChild={onDeleteChild}
                createChild={onCreateChild} />
        </div>
    );
};

export default DatabaseMetaDetail;
