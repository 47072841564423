import { AxiosError } from 'axios';
import { Dispatch } from 'react';
import {
    showErrorSnackbar,
    showWarningSnackbar
} from '../store/messageSnackbarSlice';

export const handleError = (error: AxiosError) => {
    throw error;
};

/**
 * General function for handling all errors returned by Axios functions
 * @param error AxiosError returned by catch function
 * @param dispatch Dispatch element from calling page
 * @param props.setStatus (optional) setStatus function from the calling formik element. Required to set EditErrors from the service
 * @param props.prefix (optional) string to be displayed before any error message returned by the backend service
 * @returns EditErrors, WarningSnackbar or ErrorSnackbar to the calling form
 */
export const handleErrorResponse = (
    error: AxiosError,
    dispatch: Dispatch<any>,
    props?: { setStatus?: (status?: any) => void; prefix?: string }
) => {
    if (!error.response) return;

    const errorResponse = error.isAxiosError && (error.response.data as any);
    const message = `${props?.prefix ?? ''}${
        errorResponse?.message ?? error.message
    }`;

    if (error.response.status === 422) {
        const editErrors = errorResponse.editErrors;

        if (props?.setStatus && editErrors) {
            props.setStatus(editErrors);
        } else if (editErrors && editErrors.general) {
            dispatch(showWarningSnackbar(editErrors.general));
        } else {
            dispatch(showWarningSnackbar(message));
        }
    } else if (error.response.status === 403) {
        dispatch(showWarningSnackbar(message));
    } else {
        dispatch(showErrorSnackbar(message));
    }
};
