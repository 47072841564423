import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';
import { DatabaseMeta, PagingResponse } from '../../@types';

/** 
 * Create DatabaseMeta
 */
export const createDatabaseMeta = (databaseMeta: DatabaseMeta) =>
    axiosInstance.post<DatabaseMeta>('/api/database', databaseMeta).catch(handleError);

/** 
 * Update DatabaseMeta
 */
export const updateDatabaseMeta = (databaseMeta: DatabaseMeta) =>
    axiosInstance.put<DatabaseMeta>('/api/database', databaseMeta).catch(handleError);

/** 
 * Get by id DatabaseMeta
 */
export const getDatabaseMeta = (id: number) =>
    axiosInstance.get<DatabaseMeta>(`/api/database/${id}`).catch(handleError);

/** 
* Delete by id DatabaseMeta
*/
export const deleteDatabaseMeta = (id: number) =>
    axiosInstance.delete<DatabaseMeta>(`/api/database/${id}`).catch(handleError);

/** 
* Database Page DatabaseMeta
*/
export const getDatabasePage = (page: number, size: number, sortFld: string) =>
    axiosInstance.get<PagingResponse<DatabaseMeta>>(`/api/database/page?page=${page}&size=${size}&sortFld=${sortFld}`).catch(handleError);

/** 
* Get all databases by project id
*/
export const getAllByProjectId = (id: number) =>
    axiosInstance.get<DatabaseMeta[]>(`/api/database/project/${id}`).catch(handleError);