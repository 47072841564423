import React, { useState } from 'react';
import { BEditableTable, BEditableTableColumn, BOption } from 'mui-bueno';
import { FormikHelpers } from 'formik';
import { ConstraintMeta } from '../../@types';
import {
    createConstraint, deleteConstraint,
    getConstraintsByColumnId,
    updateConstraint
} from '../../service/Meta/constraintMeta';
import { Meta } from '../../common/MetaDetail/MetaDetail';



interface ConstraintListProps {
    data: Meta
}
const initialValues: ConstraintMeta = {
    name: '',
    type: '',
    id: undefined,
    sourceColumnId: 0
}

const CONSTRAINT_TYPES: BOption<string>[] = [
    {label: 'FOREIGN_KEY' , value:'FOREIGN_KEY' },
    { label: 'DEFAULT',value: 'DEFAULT' },
    { label: 'PRIMARY_KEY', value: 'PRIMARY_KEY' },
    { label : 'CHECK', value: 'CHECK' },
    { label: 'UNIQUE', value: 'UNIQUE' },
    {label: 'NOT_NULL', value: 'NOT_NULL' }
]

const ConstraintList = (props: ConstraintListProps) => {

    //TODO add validation of required fields based on type of constraint
    const [rows, setRows] = useState<ConstraintMeta[]>([]);

    React.useEffect(() => {
        getConstraintsByColumnId(Number(props.data.id)).then((res) => {
            setRows(res.data)
        })
    }, [props.data] )

    const columnKeys = [
        'name',
        'value',
        'foreignKeyColumnId'
    ]
    const onCellEdit = async (record: ConstraintMeta, val: string | number | boolean, key: string) => {

        //update edited attribute of record
        record = { ...record, [key]: val };
        //pass updated record to parent
        try {
            await updateConstraint(record);
            getConstraintsByColumnId(Number(props.data.id)).then((res) => {
                setRows(res.data)
            })
        }
        catch (error)
        {
            //showErrorSnackbar('update error');
            console.error('error while updating constraint')
        }



    };

    const columns: BEditableTableColumn<ConstraintMeta>[] = columnKeys.map(key => ({
        valueKey: key,
        sortable: true,
        width: '20%',
        required: (!(key === 'foreignKeyColumnId' || key === 'value')),
        label : key === 'name'? 'Constraint Name'
            : key.charAt(0).toUpperCase() + key.slice(1),
        onCellEdit: (record: ConstraintMeta, val: string) => onCellEdit(record, val, key)
    }));
    columns.splice(1,0,{
        valueKey: 'type',
        sortable: true,
        width: '20%',
        required: true,
        type: 'select',
        bOptions: CONSTRAINT_TYPES,
        onCellEdit: (record: ConstraintMeta, val: string) => onCellEdit(record, val, 'type')
    });

    const onSubmit = async (record: ConstraintMeta, { resetForm }: FormikHelpers<ConstraintMeta>) => {
        record.sourceColumnId = Number(props.data.id);
        try {
            await createConstraint(record);
            getConstraintsByColumnId(Number(props.data.id)).then((res) => {
                setRows(res.data)
            })
        } catch (error){
            //showErrorSnackbar('create error');
            console.error('error while creating constraint')
        }


        resetForm();


    };

    const onConfirmDelete = async (record: ConstraintMeta) => {
        //call to parent to delete child
        try {
            await deleteConstraint(Number(record.id));
            getConstraintsByColumnId(Number(props.data.id)).then((res) => {
                setRows(res.data)
            })
        } catch (error)
        {
            //showErrorSnackbar('delete error');
            console.error('error while deleting constraint')
        }



    };

    const rowDeleteProps = (record: ConstraintMeta) => ({
        objectName: record.name,
        objectType: 'Constraint Meta',
        onConfirm: () => onConfirmDelete(record)
    });



    const divStyle = { padding: '10px 0px' };
    return (
        <div style={{ padding: '20px' }}>
            <div style={divStyle}>
                <div>
                    <BEditableTable
                        id='1'
                        data={rows}
                        columns={columns}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        rowDeleteProps={rowDeleteProps}

                    />
                </div>
            </div>
        </div>
    );

}

export default ConstraintList;

