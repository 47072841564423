import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, Box } from '@mui/material';
import { MenuRounded } from '@mui/icons-material';
import useLoggedInUser from '../../hooks/useLoggedInUser';
import LoggedOut from './LoggedOut';
import LoggedIn from './LoggedIn';
import dmLogo from '../datamappa_logo_compact.png';

const Header: React.FC = () => {
    /**
     * As any features that can be viewed when logged in AND not logged in are
     * necessary, you can uncomment them and add them here.
     */
    // const publicFeatures: Feature[] = [
    //     // Add public features as necessary
    // ];

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const loggedInId = useLoggedInUser().id;

    const showLogin = !loggedInId;

    const displayHeaderUi = showLogin ? (
        <LoggedOut handleDrawerToggle={handleDrawerToggle} />
    ) : (
        <LoggedIn handleDrawerToggle={handleDrawerToggle} />
    );

    return (
        <>
            <AppBar position='sticky' data-testid='header'>
                <Toolbar className='tool-bar'>
                    <div className='title'>
                        <Link to={showLogin ? '/' : '/project'}>
                            <img
                                src={dmLogo}
                                alt='SimonComputing Logo White'
                                height={70}
                            />
                        </Link>
                    </div>
                    {/* The header will appear when the breakpoint is >= md */}
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        {displayHeaderUi}
                    </Box>
                    {/* The drawer will appear when the breakpoint is < md */}
                    <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                        <IconButton
                            onClick={handleDrawerToggle}
                            color='inherit'
                            data-testid='drawer-toggle-btn'
                        >
                            <MenuRounded />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                <Drawer
                    anchor='top'
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    data-testid='drawer'
                >
                    <div style={{ height: 70 }} />
                    {/* <List>
                        {publicFeatures.map((feature) =>
                            <ListItemButton
                                key={`feature-${feature.name}`}
                                component={AdapterLink}
                                to={feature.route}
                                onClick={handleDrawerToggle}
                            >
                                <ListItemText primary={feature.name} className="drawer-link" />
                            </ListItemButton>
                        )}
                    </List> */}
                    {displayHeaderUi}
                </Drawer>
            </Box>
        </>
    );
};

export default Header;
