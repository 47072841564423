import * as React from 'react';
import { Button, Card, Typography } from '@mui/material';
import { ProjectMeta, TableHeader } from '../../@types';
import { useNavigate } from 'react-router-dom';
import PageableList from '../../common/PageableList/PageableList';
import AddIcon from '@mui/icons-material/Add';
import { getProjectPage } from '../../service/Meta/projectMeta';


const headers: TableHeader[] = [
    { type: 'id', displayValue: 'ID' },
    { type: 'name', displayValue: 'Name' },
    { type: 'description', displayValue: 'Description' },
    { type: 'tagIds', displayValue: 'Tags' },
];

const ProjectList: React.FC = () => {
    const navigate = useNavigate();

    const onSelectProject = (project: ProjectMeta) => {
        navigate(`/project/${project.id}`)
    };

    const onAddProject = () => {
        navigate('/project/new');
    };

    return (

        <div className='page-body max-lg full-size'>
            <Card className='user-header'>
                <Typography variant='h4'>Projects</Typography>
                <Button
                    onClick={onAddProject}
                    startIcon={<AddIcon />}
                    variant='contained'
                >
                    Add Project
                </Button>
            </Card>
            <Card>
                <PageableList
                    initialOrderBy={'id'}
                    tableHeaders={headers}
                    getObjectPage={getProjectPage}
                    onRowClick={onSelectProject}
                />
            </Card>
        </div>
    );
};
export default ProjectList;
