import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';
import { ColumnMeta, PagingResponse } from '../../@types';

/** 
 * Create ColumnMeta
 */
export const createColumnMeta = (columnMeta: ColumnMeta) =>
    axiosInstance.post<ColumnMeta>('/api/column', columnMeta).catch(handleError);

/** 
 * Update ColumnMeta
 */
export const updateColumnMeta = (columnMeta: ColumnMeta) =>
    axiosInstance.put<ColumnMeta>('/api/column', columnMeta).catch(handleError);

/** 
 * Get by id ColumnMeta
 */
export const getColumnMeta = (id: number) =>
    axiosInstance.get<ColumnMeta>(`/api/column/${id}`).catch(handleError);

/** 
* Delete by id ColumnMeta
*/
export const deleteColumnMeta = (id: number) =>
    axiosInstance.delete<ColumnMeta>(`/api/column/${id}`).catch(handleError);

/** 
* Column Page ColumnMeta
*/
export const getColumnPage = (page: number, size: number, sortFld: string) =>
    axiosInstance.get<PagingResponse<ColumnMeta>>(`/api/column/page?page=${page}&size=${size}&sortFld=${sortFld}`).catch(handleError);

/** 
* Get all columns by table id
*/
export const getAllByTableId = (id: number) =>
    axiosInstance.get<ColumnMeta[]>(`/api/column/table/${id}`).catch(handleError);
