import * as React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

import { ThemeProvider } from '@mui/material/styles';
import './index.scss';
import setupAxiosInterceptors from './service/Access/setupAxiosInterceptors';
import MuiTheme from './styles/MuiTheme';

setupAxiosInterceptors();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ThemeProvider theme={MuiTheme}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
