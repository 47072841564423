import * as React from 'react';

import { Box, Typography } from '@mui/material';
import { BButton } from 'mui-bueno';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className='hero bg-white'>
            <Box textAlign='center' className='main' height={'100vh'}>
                <Typography gutterBottom variant='h1'>
                    Page Not Found
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                    The page you are looking for does not exist.
                </Typography>
                <BButton
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                        navigate('/');
                    }}
                    className='home-button'
                >
                    Back to home
                </BButton>
            </Box>
        </div>
    );
};
export default NotFound;
