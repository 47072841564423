import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { TableHeader, Tag } from '../../@types';
import { getTagPage } from '../../service/Management/tags';
import PageableList from '../../common/PageableList/PageableList';

const headers: TableHeader[] = [
    { type: 'id', displayValue: 'ID' },
    { type: 'name', displayValue: 'Name' },
    { type: 'description', displayValue: 'Description' },
    { type: 'url', displayValue: 'Url' }
];

const TagList: React.FC = () => {
    const navigate = useNavigate();

    const onSelectTag = (tag: Tag) => {
        navigate(`/tag/${tag.id}`);
    };

    const onAddTag = () => {
        navigate('/tag/new');
    };

    return (
        <div className='page-body max-lg full-size'>
            <Card className='user-header'>
                <Typography variant='h4'>Tags</Typography>
                <Button
                    onClick={onAddTag}
                    startIcon={<AddIcon />}
                    variant='contained'
                >
                    Add Tag
                </Button>
            </Card>
            <Card>
                <PageableList
                    initialOrderBy={'id'}
                    tableHeaders={headers}
                    getObjectPage={getTagPage}
                    onRowClick={onSelectTag}
                />
            </Card>
        </div>

    );
};

export default TagList;
