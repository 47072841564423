import { AccountTree, Storage, Schema, ChevronRight, ExpandMore, TableView } from '@mui/icons-material';
import { TreeItem, TreeItemProps, TreeView, treeItemClasses } from '@mui/lab';
import { DatabaseMeta, ProjectMeta, SchemaMeta, TableMeta } from '../../@types';
import * as React from 'react';
import { Box, Collapse, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { deleteDatabaseMeta, getAllByProjectId, getDatabaseMeta } from '../../service/Meta/databaseMeta';
import { deleteSchemaMeta, getAllByDatabaseId, getSchemaMeta } from '../../service/Meta/schemaMeta';
import { deleteTableMeta, getAllBySchemaId, getTableMeta } from '../../service/Meta/tableMeta';
import { deleteProjectMeta, getAllProjects } from '../../service/Meta/projectMeta';
import { useDispatch, useSelector } from 'react-redux';
import { setParentId } from '../../store/parentIdSlice';
import { setPendingUpdate, unsetPendingUpdate } from '../../store/sidebarSlice';
import { RootState } from '../../store/store';

interface ProjectItem {
    selectedType: string,
    id: number
}

const ProjectTree: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [projects, setProjects] = React.useState<ProjectMeta[]>([]);
    const [databases, setDatabases] = React.useState<DatabaseMeta[]>([]);
    const [schemas, setSchemas] = React.useState<SchemaMeta[]>([]);
    const [tables, setTables] = React.useState<TableMeta[]>([]);
    const [contextMenuPosition, setContextMenuPosition] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const pendingUpdate = useSelector<RootState, boolean>(
        (store) => store.sidebar.pendingUpdate
    );

    React.useEffect(() => {
        const fetchProjects = async () => {
            try {
                const projectsResponse = await getAllProjects();
                setProjects(projectsResponse.data);
                dispatch(unsetPendingUpdate());
            } catch (error) {
                console.error(error);
            }
        };
        fetchProjects();
    }, [pendingUpdate]);

    React.useEffect(() => {
        const fetchDatabases = async () => {
            const allDatabases: DatabaseMeta[] = []; // Create a temporary array to hold all databases
            for (const project of projects) {
                try {
                    const databasesResponse = await getAllByProjectId(project.id as number);
                    const databasesData = databasesResponse.data;
                    allDatabases.push(...databasesData); // Add new databases to the temporary array
                } catch (error) {
                    console.error(error);
                }
            }
            setDatabases(allDatabases); // Update the state with the new array of databases
        };
        if (projects.length > 0) {
            fetchDatabases();
        }
    }, [projects]);

    React.useEffect(() => {
        const fetchSchemas = async () => {
            const allSchemas: SchemaMeta[] = [];
            for (const database of databases) {
                try {
                    const schemasResponse = await getAllByDatabaseId(database.id as number);
                    const schemasData = schemasResponse.data;
                    allSchemas.push(...schemasData)
                } catch (error) {
                    console.error(error);
                }
            }
            setSchemas(allSchemas);
        };
        if (databases.length > 0) {
            fetchSchemas();
        }
    }, [databases]);

    React.useEffect(() => {
        const fetchTables = async () => {
            const allTables: TableMeta[] = [];
            for (const schema of schemas) {
                try {
                    const tablesResponse = await getAllBySchemaId(schema.id as number);
                    const tablesData = tablesResponse.data;
                    allTables.push(...tablesData)
                } catch (error) {
                    console.error(error);
                }
            }
            setTables(allTables)
        };

        if (schemas.length > 0) {
            fetchTables();
        }
    }, [schemas]);

    const [menuItems, setMenuItems] = React.useState<ProjectItem[]>([{ selectedType: '', id: 9999 }]);

    function TransitionComponent(props: TransitionProps) {
        return (
            <Collapse {...props} />
        );
    }

    const StyledTreeItem = styled((props: TreeItemProps) => (
        <TreeItem className="dont-close-sidebar" {...props} TransitionComponent={TransitionComponent} />
    ))(() => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3,
            },
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: '1px dashed',
            borderColor: 'transparent',
            '&:hover': {
                borderColor: 'white',
            },
        },
    }));

    const handleTextClick = (event: React.MouseEvent, selectedType: string, nodeId: string, id: number | undefined) => {
        event.preventDefault();
        navigate(`${selectedType}/${id}`);
    };

    const handleContextMenu = (event: React.MouseEvent, selectedItemType: string, selectedItemId: number) => {
        event.preventDefault();
        dispatch(setParentId(selectedItemId)); // Dispatch the setParentId action with the id as argument
        updateContextMenu(selectedItemType, selectedItemId);
        setContextMenuPosition(
            contextMenuPosition === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : null,
        );

    };

    const updateContextMenu = (selectedItemType: string, selectedItemId: number) => {
        const m: Record<string, string> = {
            'project': 'database',
            'database': 'schema',
            'schema': 'table'
        };

        const newItemType: string | undefined = m[selectedItemType];
        const deleteItemType: string = selectedItemType || '';

        if (selectedItemType === 'table') {
            setMenuItems([{ selectedType: `Delete ${deleteItemType}`, id: selectedItemId }]);
            return
        }

        setMenuItems([{ selectedType: `Create new ${newItemType}`, id: selectedItemId }, { selectedType: `Delete ${deleteItemType}`, id: selectedItemId }]);
    }

    const handleClose = () => {
        setContextMenuPosition(null);
    };

    const handleMenuClick = (item: string, id: number) => {
        const path = window.location.pathname
        if (item.includes('Create')) {
            navigate(`${item.split(' ')[2]}/new`)
        }
        if (item.includes('Delete')) {
            if (item.includes('project')) {
                deleteProjectMeta(id).catch(err => console.error(err))
                dispatch(setPendingUpdate())
                //if you right click delete and you are currently on the DetailPage of whatever you are visiting, you should navigate to the parent
                if (path == `/project/${id}`) {
                    navigate('/project')
                }
            }
            else if (item.includes('database')) {
                deleteDatabaseMeta(id).catch(err => console.error(err))
                dispatch(setPendingUpdate())
                if (path == `/database/${id}`) {
                    getDatabaseMeta(id).then(res => {
                        navigate(`/project/${res.data.projectId}`)
                    }).catch(err => console.error(err))
                }
            }
            else if (item.includes('schema')) {
                deleteSchemaMeta(id).catch(err => console.error(err))
                dispatch(setPendingUpdate())
                if (path == `/schema/${id}`) {
                    getSchemaMeta(id).then(res => {
                        navigate(`/database/${res.data.databaseId}`)
                    }).catch(err => console.error(err))
                }
            }
            else if (item.includes('table')) {
                deleteTableMeta(id).catch(err => console.error(err))
                dispatch(setPendingUpdate())
                if (path == `/table/${id}`) {
                    getTableMeta(id).then(res => {
                        navigate(`/schema/${res.data.schemaId}`)
                    }).catch(err => console.error(err))
                }
            }
        }
        handleClose()
    }

    const treeLabel: React.FC<{ metaType: string, metaObject: ProjectMeta | DatabaseMeta | SchemaMeta | TableMeta }> = ({ metaType, metaObject, }) => {
        return (
            <Box
                sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}
                onClick={(event) => handleTextClick(event, metaType, metaObject.name, metaObject.id)}
                onContextMenu={(event) => handleContextMenu(event, metaType, metaObject.id as number)}
            >
                {metaType === 'project' && <Box component={AccountTree} sx={{ pr: '5px' }}></Box>}
                {metaType === 'database' && <Box component={Storage} sx={{ pr: '5px' }}></Box>}
                {metaType === 'schema' && <Box component={Schema} sx={{ pr: '5px' }}></Box>}
                {metaObject.name}
            </Box>
        )
    }

    return (
        <div className='project_tree' style={{ height: '100%', width: '100%' }}>
            <div style={{ cursor: 'context-menu' }}>
                <Menu
                    open={contextMenuPosition !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenuPosition !== null
                            ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
                            : undefined
                    }
                >
                    {menuItems.map((item, index) => (
                        <MenuItem key={index} onClick={() => handleMenuClick(item.selectedType, item.id)}>{item.selectedType}</MenuItem>
                    ))}
                </Menu>

                <div className='treeStyle'>
                    <TreeView
                        aria-label='file system navigator'
                        defaultCollapseIcon={<ExpandMore className="dont-close-sidebar" />}
                        defaultExpandIcon={<ChevronRight className="do-close-sidebar" />}
                    >
                        {projects.map((project) => {
                            return (
                                <StyledTreeItem
                                    key={`project-${project.id}`}
                                    nodeId={project.name}
                                    label={treeLabel({ metaType: 'project', metaObject: project })}
                                >
                                    {databases.filter((database) => database.projectId == project.id).map((database) => {
                                        return (
                                            <StyledTreeItem
                                                nodeId={database.name}
                                                key={`database-${database.id}`}
                                                label={treeLabel({ metaType: 'database', metaObject: database })}
                                            >
                                                {schemas.filter((schema) => schema.databaseId == database.id).map((schema) => {
                                                    return (
                                                        <StyledTreeItem
                                                            nodeId={schema.name}
                                                            key={`schema-${schema.id}`}
                                                            label={treeLabel({ metaType: 'schema', metaObject: schema })}
                                                        >
                                                            {tables.filter((table) => table.schemaId == schema.id).map((table) => {
                                                                return (
                                                                    <Box
                                                                        className='sidebar-table-button'
                                                                        key={`table-${table.id}`}
                                                                        color='inherit'
                                                                        style={{ fontSize: '.95rem', fontWeight: '400', fontFamily: 'Roboto' }}
                                                                        sx={{ paddingLeft: '20px', display: 'flex', alignItems: 'center', pt: 0.5, pb: 0.5, pr: 1.5, width: '200' }}
                                                                        onClick={(event) => handleTextClick(event, 'table', table.name, table.id)}
                                                                        onContextMenu={(event) => handleContextMenu(event, 'table', table.id as number)}
                                                                    >
                                                                        <Box component={TableView} sx={{ pr: '5px', fontSize: 'xxlarge' }} ></Box>
                                                                        {table.name}
                                                                    </Box>
                                                                );
                                                            })}
                                                        </StyledTreeItem>
                                                    );
                                                })}
                                            </StyledTreeItem>
                                        );
                                    })}
                                </StyledTreeItem>
                            );
                        })}
                    </TreeView>
                </div>
            </div >
        </div >
    );
};

export default ProjectTree;