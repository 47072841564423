import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { SidebarState } from '../../store/sidebarSlice';
import useLoggedInUser from '../hooks/useLoggedInUser';
import EnvHeader from './EnvHeader/EnvHeader';
//temporarily removing footer
// import Footer from './Footer/Footer';
import Header from './Header/Header';
import ProjectExplorer from '../../features/ProjectExplorer/ProjectExplorer';
import { RootState } from '../../store/store';

const Layout: React.FC<{ children: any }> = (props) => {
    const loggedIn = useLoggedInUser().id;
    const { expanded } = useSelector<RootState, SidebarState>(
        (store) => store.sidebar
    );

    if (loggedIn) {
        return (
            <div className='navigation'>
                <EnvHeader />
                <Header />
                <Box sx={{ display: 'flex' }}>
                    <ProjectExplorer />
                    <div className={`app-content sidebar-${expanded ? 'expanded' : 'collapsed'}`}>
                        {props.children}
                    </div>
                </Box>
            </div>
        );
    }
    return (
        <div className='navigation'>
            <EnvHeader />
            <Header />

            <Box sx={{ display: 'flex' }}>
                <div className={`app-content sidebar-${expanded ? 'expanded' : 'collapsed'}`}>
                    {props.children}
                </div>
            </Box>
            {/* <Footer /> */}
        </div>
    );
};

export default Layout;