import * as React from 'react';
import {FormControl, InputLabel, Select, OutlinedInput, MenuItem, SelectChangeEvent, Checkbox, ListItemText, Grid} from '@mui/material';
import { ProjectMeta } from '../../@types';
import { getAllProjects } from '../../service/Meta/projectMeta';
import { handleErrorResponse } from '../../service/utils';
import { useDispatch } from 'react-redux';

interface Props {
    projectType : 'SOURCE' | 'DESTINATION';
    setLegacyProjects : (projects : ProjectMeta[]) => void;
    setModernProjects : (projects : ProjectMeta[]) => void;
    alreadySelectedProject : ProjectMeta[];
    editMode: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ComparisonViewSelect : React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const editMode: string = props.editMode;
    const [projects, setProjects] = React.useState<ProjectMeta[]>([]);
    const [selectProjects, setSelectProjects] = React.useState<ProjectMeta[]>([]);
    const [selectedProjects, setSelectedProjects] = React.useState<ProjectMeta[]>([]);

    React.useEffect(() => {
        getAllProjects()
            .then((res) => {
                setProjects(res.data);
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            })
    }, [])
    // props.alreadySelectedProject
    React.useEffect(() => {

        setSelectProjects(filterProjects(projects, props.alreadySelectedProject));
        if(props.projectType === 'SOURCE'){
            props.setLegacyProjects(selectedProjects);
        }
        else if(props.projectType === 'DESTINATION'){
            props.setModernProjects(selectedProjects);
        }

    },[selectedProjects.length, selectProjects])

    const filterProjects = (globalProjects : ProjectMeta[], alreadyProjects : ProjectMeta[]) => {
        const filteredProjects = globalProjects.filter((project) => {
            return !alreadyProjects.some((alreadyProject) => alreadyProject.id === project.id);
        });
        
        return filteredProjects; 
    }

    const handleProjectSelectionChange = (event : SelectChangeEvent<ProjectMeta[]>) => {
        const selected = event.target.value as ProjectMeta[];
        setSelectedProjects([...selected]);
    }


    return (
        editMode === '' ? 
            <Grid alignItems="center">
                <FormControl sx={{ m: 1, width: 300 }} variant='outlined'>
                    <InputLabel>Projects</InputLabel>
                    <Select
                        multiple
                        value={selectedProjects}
                        input={<OutlinedInput label='Chip' />}
                        onChange = {handleProjectSelectionChange}
                        MenuProps={MenuProps}
                        renderValue={()=>''}
                    >
                        {selectProjects.map((project) => (
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            <MenuItem
                                key={project.name}
                                value={project}
                            >
                                <Checkbox checked={selectedProjects.includes(project)} />
                                <ListItemText primary={project.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> 
            </Grid>
            :
            null
    )
}

export default ComparisonViewSelect;