import * as React from 'react';

import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch } from 'react-redux';
import {
    clearSnackbar,
    MessageSnackbarState
} from '../../store/messageSnackbarSlice';
import { RootState } from '../../store/store';

const MessageSnackbar: React.FC = () => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const { message, type } = useSelector<RootState, MessageSnackbarState>(
        (store) => store.messageSnackbar
    );

    const close = () => {
        setOpen(false);
        dispatch(clearSnackbar());
    };

    React.useEffect(() => {
        if (message) {
            setOpen(true);
        }
    }, [message]);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            open={open}
            key={`${type}-snackbar`}
            autoHideDuration={10000}
            onClose={close}
        >
            <SnackbarContent
                className={`message-snackbar ${type}`}
                message={message}
                action={
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Snackbar>
    );
};

export default MessageSnackbar;
