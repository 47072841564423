import { ConstraintMeta } from '../../@types';
import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';

export const createConstraint = (constraint: ConstraintMeta) =>
    axiosInstance
        .post<ConstraintMeta>('/api/constraint', constraint)
        .catch(handleError);


export const updateConstraint = (constraint: ConstraintMeta) =>
    axiosInstance
        .put<ConstraintMeta>('/api/constraint', constraint)
        .catch(handleError)


export const getConstraint = (id: number) =>
    axiosInstance.get<ConstraintMeta>(`/api/constraint/${id}`)
        .catch(handleError);


export const deleteConstraint = (id: number) =>
    axiosInstance.delete<ConstraintMeta>(`/api/constraint/${id}`)
        .catch(handleError);


export const getConstraintsByColumnId = (id : number) =>
    axiosInstance.get<ConstraintMeta[]>(`/api/constraint/columnId/${id}`)
        .catch(handleError);


export const getConstraintsByType = (type : string, page: number, size: number, sortField: string) =>
    axiosInstance.get<ConstraintMeta[]>(`/api/constraint/type/${type}?page=${page}&size=${size}&sortField=${sortField}`)
        .catch(handleError);
