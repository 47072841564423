import React from 'react';
import * as yup from 'yup';
import { Divider, Typography, Button } from '@mui/material';
import { FormikHelpers } from 'formik';
import { BForm, BFormik, BPassword, BSubmit } from 'mui-bueno';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { showSuccessSnackbar } from '../../../store/messageSnackbarSlice';
import { changePassword } from '../../../service/Management/users';
import { ChangePasswordRequest } from '../../../@types';
import { handleErrorResponse } from '../../../service/utils';

const schema = yup.object().shape({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup.string().required('New Password is required'),
});

const ChangePassword: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = {
        oldPassword: '',
        newPassword: '',
    };

    const handleSubmit = (
        data: ChangePasswordRequest,
        { setErrors }: FormikHelpers<ChangePasswordRequest>
    ) => {
        changePassword(data)
            .then(() => {
                dispatch(showSuccessSnackbar('Password changed successfully'));
                navigate('/dashboard');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    setStatus: setErrors,
                    prefix: 'Could not change password: ',
                });
            });
    };

    return (
        <div className='access-form'>
            <BFormik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={schema}
            >
                <BForm>
                    <div className='card'>
                        <Typography variant='h2' align='center'>
                            Change Password
                        </Typography>
                        <Divider style={{ backgroundColor: '#F0F0F0' }} variant='fullWidth' />
                        <BPassword name='oldPassword' label='Old Password' variant='filled' />
                        <BPassword name='newPassword' label='New Password' variant='filled' />
                        <Divider style={{ backgroundColor: '#F0F0F0' }} variant='fullWidth' />
                        <div className='right-buttons'>
                            <Button
                                name="cancel"
                                className="cancel-button"
                                style={{ marginTop: '15px' }}
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <BSubmit
                                name='savePassword'
                                className='password-button'
                                style={{ marginTop: '15px', marginLeft: '5px'}}
                            >
                                Save Password
                            </BSubmit>
                        </div>
                    </div>
                </BForm>
            </BFormik>
        </div>
    );
};

export default ChangePassword;
