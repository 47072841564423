import { useSelector } from 'react-redux';

import { User } from '../../@types';
import { RootState } from '../../store/store';

const useLoggedInUser = (): User => {
    return useSelector<RootState, User>((store) => store.login.user);
};

export default useLoggedInUser;
