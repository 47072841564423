import { Transformation } from '../../@types';
import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';

export const createTransformation = (transformation: Transformation) =>
    axiosInstance
        .post<Transformation>('/api/transformation', transformation)
        .catch(handleError);

export const updateTransformation = (transformation: Transformation) =>
    axiosInstance
        .put<Transformation>('/api/transformation', transformation)
        .catch(handleError);

export const getTransformation = (id: number) =>
    axiosInstance
        .get<Transformation>(`/api/transformation/${id}`)
        .catch(handleError);

export const deleteTransformation = (id: number) =>
    axiosInstance
        .delete<Transformation>(`/api/transformation/${id}`)
        .catch(handleError);

export const getByColumnId = (id: number) =>
    axiosInstance.get<Transformation[]>(`/api/transformation/columnId/${id}`).catch(handleError);

export const getSourceColumnIds = (id: number) =>
    axiosInstance
        .get<number[]>(`/api/transformation/sourcecolumns?id=${id}`)
        .catch(handleError);

export const getDestinationColumnIds = (id: number) =>
    axiosInstance
        .get<number[]>(`/api/transformation/destinationcolumns?id=${id}`)
        .catch(handleError);