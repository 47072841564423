import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MessageSnackbarType = 'success' | 'error' | 'warning' | 'general';

export interface MessageSnackbarState {
    message: string;
    type: MessageSnackbarType;
}
  
export const initialState: MessageSnackbarState = {
    message: '',
    type: 'success'
};

export const messageSnackbarSlice = createSlice({
    name: 'messageSnackbar',
    initialState,
    reducers: {
        showSuccessSnackbar: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.type = 'success';
        },
        showErrorSnackbar: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.type = 'error';
        },
        showWarningSnackbar: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.type = 'warning';
        },
        showGeneralSnackbar: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.type = 'general';
        },
        clearSnackbar: () => {
            return { ...initialState }
        }
    },
});

export const { 
    showSuccessSnackbar, showErrorSnackbar, showWarningSnackbar, showGeneralSnackbar, clearSnackbar
} = messageSnackbarSlice.actions;

export default messageSnackbarSlice.reducer;
