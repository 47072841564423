import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { FormikHelpers } from 'formik';
import * as yup from 'yup';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    BConfirmButton,
    BButton,
    BEmail,
    BError,
    BForm,
    BFormik,
    BGrid,
    BOption,
    BPassword,
    BSelect,
    BSubmit,
    BT,
    BTextField
} from 'mui-bueno';

import { User, RolePageRequest } from '../../../@types';
import { handleErrorResponse } from '../../../service/utils';
import { getRolePage } from '../../../service/Management/roles';
import {
    getUser,
    updateUser,
    createUser,
    deleteUser
} from '../../../service/Management/users';
import { showSuccessSnackbar } from '../../../store/messageSnackbarSlice';

const schema = yup.object<User>().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup
        .string()
        .email('Must be in email format')
        .required('Email is required')
});

interface RouteParams extends Record<string, string> {
    userId: string; // new or id
}
const UserDetail: React.FC = () => {
    const { userId } = useParams<RouteParams>();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [user, setUser] = React.useState<User>({
        id: undefined,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roleIds: []
    });
    const [roles, setRoles] = React.useState<BOption<string>[]>([]);

    React.useEffect(() => {
        const pageReq: RolePageRequest = {
            page: 0,
            size: 20,
            sort: 'id'
        };

        getRolePage(pageReq)
            .then((res) => {
                const tempRoles: BOption<string>[] = [];
                res.data.list.forEach((role) => {
                    tempRoles.push({
                        value: role.id?.toString() ?? '-1',
                        label: role.name
                    });
                });
                setRoles(tempRoles);
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Role options: '
                });
            });
    }, []);

    React.useEffect(() => {
        if (userId === 'new') return;

        getUser(Number(userId))
            .then((res) => {
                setUser(res.data);
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve User: '
                });
            });
    }, [userId]);

    const handleSubmit = (data: User, { setErrors }: FormikHelpers<User>) => {
        if (data.id) {
            updateUser(data)
                .then((res) => {
                    setUser(res.data);
                    dispatch(
                        showSuccessSnackbar(
                            `${res.data.firstName} ${res.data.lastName} updated`
                        )
                    );
                })
                .catch((err) => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not update User: '
                    });
                });
        } else {
            createUser(data)
                .then((res) => {
                    navigate(`/user/${res.data.id}`);
                    dispatch(
                        showSuccessSnackbar(
                            `${res.data.firstName} ${res.data.lastName} created`
                        )
                    );
                    setUser(res.data);
                })
                .catch((err) => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not create User: '
                    });
                });
        }
    };

    const handleConfirmDelete = () => {
        if (!user.id) return;
        const { firstName, lastName } = user;
        deleteUser(user.id)
            .then(() => {
                navigate('/user');
                dispatch(
                    showSuccessSnackbar(`${firstName} ${lastName} deleted`)
                );
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not delete User: '
                });
            });
    };

    return (
        <BFormik
            initialValues={user}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnChange
            enableReinitialize
        >
            <div className='page'>
                <div className='page-body max-md full-size'>
                    <BButton
                        variant='text'
                        onClick={() => navigate('/user')}
                        startIcon={<KeyboardBackspaceIcon />}
                        style={{ margin: '15px 0px 10px' }}
                    >
                        Back
                    </BButton>
                    <BForm>
                        <Card className='management-form'>
                            <CardHeader
                                title={
                                    <BT variant='h4' align='center'>
                                        {user.id
                                            ? `${user.firstName} ${user.lastName}`
                                            : 'Add User'}
                                        <BError id='general' name='general' />
                                    </BT>
                                }
                            />
                            <CardContent>
                                <BGrid container spacing={1} alignment='center'>
                                    <BTextField
                                        name='firstName'
                                        variant='filled'
                                        xs={12}
                                        sm={6}
                                        noMP
                                    />
                                    <BTextField
                                        name='lastName'
                                        variant='filled'
                                        xs={12}
                                        sm={6}
                                        noMP
                                    />
                                    <BEmail
                                        name='email'
                                        variant='filled'
                                        xs={12}
                                        noMP
                                    />
                                    <BTextField
                                        name='roleIds'
                                        variant='filled'
                                        xs={12}
                                        sm={6}
                                        noMP
                                    />
                                    {!user.id && (
                                        <BPassword
                                            name='password'
                                            complexity={{
                                                minimum: 8,
                                                capital: 1,
                                                lowercase: 1,
                                                number: 1,
                                                special: 1
                                            }}
                                            variant='filled'
                                            xs={12}
                                            noMP
                                        />
                                    )}
                                    <BSelect
                                        name='roleIds'
                                        label='Roles'
                                        options={roles}
                                        multiple
                                        renderValue={(selected) => (
                                            <div className='chips'>
                                                {(selected as string[]).map(
                                                    (value) => {
                                                        const option: BOption<string> | null =
                                                            roles[+value];
                                                        return (
                                                            option && (
                                                                <Chip
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    label={
                                                                        option.label
                                                                    }
                                                                    className='chip'
                                                                />
                                                            )
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                        variant='filled'
                                        xs={12}
                                        noMP
                                    />
                                </BGrid>
                            </CardContent>
                            <CardActions>
                                {user.id ? (
                                    <BConfirmButton
                                        objectType='User'
                                        objectName={`${user.firstName} ${user.lastName}`}
                                        onConfirm={handleConfirmDelete}
                                    />
                                ) : (
                                    <div />
                                )}
                                <BSubmit startIcon={<SaveIcon />}>Save</BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </div>
            </div>
        </BFormik>
    );
};

export default UserDetail;
