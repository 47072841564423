import React, { SyntheticEvent, useState } from 'react';
import ComparisonViewTree, { COLUMN_LABEL, TABLE_LABEL, SCHEMA_LABEL, DATABASE_LABEL, PROJECT_LABEL } from './ComparisonViewTree';
import ComparisonViewSelect from './ComparisonViewSelect';

import TransformationDetail from './TransformationDetail';
import { Button, Snackbar } from '@mui/material';
import { ColumnMeta, DatabaseMeta, ProjectMeta, SchemaMeta, TableMeta, Transformation } from '../../@types';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getByColumnId } from '../../service/Meta/transformation';
import { getColumnMeta } from '../../service/Meta/columnMeta';
import { getTableMeta } from '../../service/Meta/tableMeta';
import { getSchemaMeta } from '../../service/Meta/schemaMeta';
import { getDatabaseMeta } from '../../service/Meta/databaseMeta';
import { getProjectMeta } from '../../service/Meta/projectMeta';

const ComparisonView: React.FC = () => {

    const [showSidebar, setShowSidebar] = useState(false);
    const [sidebarMode, setSidebarMode] = useState('');
    const [transformationSourceExpandedNames, setTransformationSourceExpandedNames] = useState<string[]>([]);
    const [transformationDestinationExpandedNames, setTransformationDestinationExpandedNames] = useState<string[]>([])
    const [transformationSourceColumnNames, setTransformationSourceColumnNames] = useState<string[]>([])
    const [tranformationDestinationColumnNames, setTransformationDestinationColumnNames] = useState<string[]>([])
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [create, setCreate] = React.useState<boolean>(false);
    const [selectedTransformations, setSelectedTransformations] = useState<Transformation[]>([]);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
        setSidebarMode('update');
    }

    const editMappingButton = () => {
        if (sidebarMode != 'update') {
            toggleSidebar();
        }
        setSidebarMode('edit');
    }


    const createMappingButton = () => {
        if (sidebarMode != 'update') {
            toggleSidebar();
        }
        setSidebarMode('edit');
        setCreate(true);
    }

    const closeMappingButton = () => {
        revertSelected();
        setShowSidebar(false);
        setSidebarMode('');
        setCreate(false);
    }

    const exit = () => {
        window.history.back();
    }

    const [legacyProjects, setLegacyProjects] = React.useState<ProjectMeta[]>([]);
    const [modernProjects, setModernProjects] = React.useState<ProjectMeta[]>([]);

    const handleToClose = (event: Event | SyntheticEvent<any, Event>, reason: any) => {
        if ('clickaway' == reason) return;
        setOpenSnackBar(false);
    };

    const [selectedLegacy, setSelectedLegacy] = useState([] as ColumnMeta[]);
    const [selectedModern, setSelectedModern] = useState([] as ColumnMeta[]);

    // Callback functions to update the state in the parent component
    const handleLegacySelection = (selected: ColumnMeta[]) => {
        setSelectedLegacy(selected);
    };

    const handleModernSelection = (selected: ColumnMeta[]) => {
        setSelectedModern(selected);
    };

    const setToDescription = () => {
        setSidebarMode('update');
    }

    const expandAndHighlight = async (transformation : Transformation) => {

        const legacyList: ColumnMeta[] = []; // Originally selectedLegacy and selectedModern never got values, just do it here
        const modernList: ColumnMeta[] = [];
        const sourceColumns: string[] = [];
        const destinationColumns: string[] = [];

        const legacyExpandedNames: string[] = []; // Empty array of strings to hold the name of the legacy columns involved in transformations

        for (const source of transformation.sourceIds) {
            const legacyNameList: string[] = [];

            let column: ColumnMeta;
            try {
                const columnsResponse = await getColumnMeta(source);
                column = columnsResponse.data;
                legacyNameList.push(COLUMN_LABEL + Number(column.id).toString());
                sourceColumns.push(COLUMN_LABEL + Number(column.id).toString());
                if (!legacyList.some(current => current.id === column.id)) {
                    legacyList.push(column); // Add to the column list for what we've selected
                }
            }
            catch (error) {
                throw new Error('Error fetching column by source ID');
            }

            let table: TableMeta;
            try {
                const tableResponse = await getTableMeta(column.tableId);
                table = tableResponse.data;
                legacyNameList.push(TABLE_LABEL + Number(table.id).toString());
            }
            catch (error) {
                throw new Error('Error fetching table by column ID');
            }

            let schema: SchemaMeta;
            try {
                const schemaResponse = await getSchemaMeta(table.schemaId);
                schema = schemaResponse.data;
                legacyNameList.push(SCHEMA_LABEL + Number(schema.id).toString());
            }
            catch (error) {
                throw new Error('Error fetching schema by table ID');
            }

            let database: DatabaseMeta;
            try {
                const databaseResponse = await getDatabaseMeta(schema.databaseId);
                database = databaseResponse.data;
                legacyNameList.push(DATABASE_LABEL + Number(database.id).toString());
            }
            catch (error) {
                throw new Error('Error fetching database by schema ID');
            }

            let project: ProjectMeta;
            try {
                const projectResponse = await getProjectMeta(database.projectId);
                project = projectResponse.data;
                legacyNameList.push(PROJECT_LABEL + Number(project.id).toString());
            }
            catch (error) {
                throw new Error('Error fetching project by database ID');
            }

            for (let i = 0; i < legacyNameList.length; i++) {
                if (legacyExpandedNames.indexOf(legacyNameList[i]) < 0)
                    legacyExpandedNames.push(legacyNameList[i])
            }
        }

        const modernExpandedNames: string[] = [];

        for (const destination of transformation.destinationIds) {
            const modernNameList: string[] = [];

            let column: ColumnMeta;
            try {
                const columnsResponse = await getColumnMeta(destination);
                column = columnsResponse.data;
                modernNameList.push(COLUMN_LABEL + Number(column.id).toString());
                destinationColumns.push(COLUMN_LABEL + Number(column.id).toString());
                if (!modernList.some(current => current.id === column.id)) {
                    modernList.push(column)
                }
            }
            catch (error) {
                throw new Error('Error fetching column by source ID');
            }

            let table: TableMeta;
            try {
                const tableResponse = await getTableMeta(column.tableId);
                table = tableResponse.data;
                modernNameList.push(TABLE_LABEL + Number(table.id).toString());
            }
            catch (error) {
                throw new Error('Error fetching table by schema ID');
            }

            let schema: SchemaMeta;
            try {
                const schemaResponse = await getSchemaMeta(table.schemaId);
                schema = schemaResponse.data;
                modernNameList.push(SCHEMA_LABEL + Number(schema.id).toString());
            }
            catch (error) {
                throw new Error('Error fetching schema by table ID');
            }

            let database: DatabaseMeta;
            try {
                const databaseResponse = await getDatabaseMeta(schema.databaseId);
                database = databaseResponse.data;
                modernNameList.push(DATABASE_LABEL + Number(database.id).toString());
            }
            catch (error) {
                throw new Error('Error fetching database by schema ID');
            }

            let project: ProjectMeta;
            try {
                const projectResponse = await getProjectMeta(database.projectId);
                project = projectResponse.data;
                modernNameList.push(PROJECT_LABEL + Number(project.id).toString());
            }
            catch (error) {
                throw new Error('Error fetching project by database ID');
            }

            for (let i = 0; i < modernNameList.length; i++) {
                if (modernExpandedNames.indexOf(modernNameList[i]) < 0)
                    modernExpandedNames.push(modernNameList[i])
            }
        }


        setTransformationSourceExpandedNames(legacyExpandedNames);
        setTransformationDestinationExpandedNames(modernExpandedNames);

        setTransformationSourceColumnNames(sourceColumns);
        setTransformationDestinationColumnNames(destinationColumns);

        setSelectedLegacy(legacyList);
        setSelectedModern(modernList);

    }

    const chooseSelected = async (selectedColumn: ColumnMeta) => {
        let transformations: Transformation[];
        try {
            const transformationsResponse = await getByColumnId(selectedColumn.id as number);
            transformations = transformationsResponse.data; // Grabs transformations for the column id
            setSelectedTransformations(transformations);
        }
        catch (error) {
            throw new Error('Error fetching transformations from selected column');
        }

        if (transformations && transformations.length > 0 && transformations[0]) {
            expandAndHighlight(transformations[0]);
            setOpenSnackBar(false);
            setSidebarMode('update');
            if (!showSidebar) {
                setShowSidebar(true);
            }
        }

        else {
            setOpenSnackBar(true);
            setTransformationSourceColumnNames([]); // There are no transformations to be shown
            setTransformationDestinationColumnNames([]);
            setSidebarMode(''); // Close out the side bar
            setShowSidebar(false);
        }
    }

    const revertSelected = async () => {
        setSelectedLegacy([]);
        setSelectedModern([]);
        setTransformationSourceColumnNames([]);
        setTransformationDestinationColumnNames([]);
        return;
    }

    function handleClickClose() {
        setOpenSnackBar(false);
    }

    const cancelMappingsButton = () => {
        setSidebarMode('update');
    }

    //The list of modern/legacy projects will be passed in as props to the comparison trees
    return (
        <div className='overallContainer'>
            <div className='buttonContainer'>
                <Button variant="contained" onClick={exit}>Exit</Button>
            </div>
            <div className='comparisonContainer'>
                <div className='divider'></div>
                <div className='treeContainer'>
                    <ComparisonViewSelect projectType={'SOURCE'} setLegacyProjects={setLegacyProjects} setModernProjects={setModernProjects} alreadySelectedProject={modernProjects} editMode={sidebarMode} />

                    <ComparisonViewTree projects={legacyProjects} type='SOURCE' editMode={sidebarMode} expandedNames={transformationSourceExpandedNames}
                        chooseSelectedColumn={chooseSelected} transformationColumnNames={transformationSourceColumnNames}
                        onLegacySelection={handleLegacySelection} onModernSelection={handleModernSelection} selectedLegacy={selectedLegacy} selectedModern={selectedModern} />
                </div>
                <div className='divider'></div>
                <div className='treeContainer'>
                    <ComparisonViewSelect projectType={'DESTINATION'} setLegacyProjects={setLegacyProjects} setModernProjects={setModernProjects} alreadySelectedProject={legacyProjects} editMode={sidebarMode} />

                    <ComparisonViewTree projects={modernProjects} type='DESTINATION' editMode={sidebarMode}
                        expandedNames={transformationDestinationExpandedNames} chooseSelectedColumn={chooseSelected} transformationColumnNames={tranformationDestinationColumnNames}
                        onLegacySelection={handleLegacySelection} onModernSelection={handleModernSelection} selectedLegacy={selectedLegacy} selectedModern={selectedModern} />
                </div>
                <div className='divider'></div>
            </div>

            <div className='buttonContainer'>
                {showSidebar ? (
                    <Button variant="contained" onClick={closeMappingButton}>X</Button>
                ) : (
                    <Button variant="contained" onClick={createMappingButton} disabled={!(legacyProjects.length > 0) || !(modernProjects.length > 0)}>Create</Button>
                )}
            </div>

            <div className={`sidebar ${showSidebar ? 'expanded' : ''}`}>
                <TransformationDetail
                    mode={sidebarMode}
                    selectedLegacy={selectedLegacy}
                    selectedModern={selectedModern}
                    editMode={showSidebar}
                    changeLegacyColumns={handleLegacySelection}
                    changeModernColumns={handleModernSelection}
                    closeMapping={closeMappingButton}
                    revertSelected={revertSelected}
                    setToDescription={setToDescription}
                    transformations={selectedTransformations}
                    editMappingsButton={editMappingButton}
                    cancelMappingsButton={cancelMappingsButton}
                    expandAndHighlight={expandAndHighlight}
                    createMode={create}
                />
            </div>
            <div>
                <Snackbar
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                    open={openSnackBar}
                    autoHideDuration={5000}
                    message="This column has no associated transformation."
                    onClose={handleToClose}
                    action={
                        <React.Fragment>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => handleClickClose()}
                            >
                                <CloseIcon fontSize="small"
                                />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div>
        </div>
    )
}

export default ComparisonView;