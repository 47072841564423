import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';
import { SchemaMeta, PagingResponse } from '../../@types';

/** 
 * Create SchemaMeta
 */
export const createSchemaMeta = (schemaMeta: SchemaMeta) =>
    axiosInstance.post<SchemaMeta>('/api/schema', schemaMeta).catch(handleError);

/** 
 * Update SchemaMeta
 */
export const updateSchemaMeta = (schemaMeta: SchemaMeta) =>
    axiosInstance.put<SchemaMeta>('/api/schema', schemaMeta).catch(handleError);

/** 
 * Get by id SchemaMeta
 */
export const getSchemaMeta = (id: number) =>
    axiosInstance.get<SchemaMeta>(`/api/schema/${id}`).catch(handleError);

/** 
* Delete by id SchemaMeta
*/
export const deleteSchemaMeta = (id: number) =>
    axiosInstance.delete<SchemaMeta>(`/api/schema/${id}`).catch(handleError);

/** 
* schema Page SchemaMeta
*/
export const getSchemaPage = (page: number, size: number, sortFld: string) =>
    axiosInstance.get<PagingResponse<SchemaMeta>>(`/api/schema/page?page=${page}&size=${size}&sortFld=${sortFld}`).catch(handleError);

/** 
* Get all schemas by database id
*/
export const getAllByDatabaseId = (id: number) =>
    axiosInstance.get<SchemaMeta[]>(`/api/schema/database/${id}`).catch(handleError);