import { createSlice } from '@reduxjs/toolkit';

export interface SidebarState {
    expanded: boolean;
    pendingUpdate: boolean;
}

export const initialState: SidebarState = { // On refresh or first page load, the sidebar should be closed
    expanded: false,
    pendingUpdate: false
}; // Always true initially.

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggleSidebar: (state: SidebarState) => {
            return { ...state, expanded: !state.expanded }; // Any time this is called, set it to opposite
        },
        setPendingUpdate: (state: SidebarState) => {
            return { ...state, pendingUpdate: true };
        },
        unsetPendingUpdate: (state: SidebarState) => {
            return { ...state, pendingUpdate: false };
        }
    },
})

export const { toggleSidebar, setPendingUpdate, unsetPendingUpdate } = sidebarSlice.actions;

export default sidebarSlice.reducer;