import axiosInstance from '../Access/axiosInstance';
import {
    User,
    PagingResponse,
    ChangePasswordRequest,
    UserPageRequest,
    ChangeTokenPasswordRequest
} from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of users
 */
export const getUserPage = (pageReq: UserPageRequest) =>
    axiosInstance
        .get<PagingResponse<User>>('/api/users', {
            params: pageReq
        })
        .catch(handleError);

/**
 * Gets a user by id
 */
export const getUser = (id: number) =>
    axiosInstance.get<User>(`/api/users/${id}`).catch(handleError);

/**
 * Updates a user
 */
export const updateUser = (user: User) =>
    axiosInstance.put<User>('/api/users', user).catch(handleError);

/**
 * Creates a user
 */
export const createUser = (user: User) =>
    axiosInstance.post<User>('/api/users', user).catch(handleError);

/**
 * Deletes a user
 */
export const deleteUser = (id: number) =>
    axiosInstance.delete(`/api/users/${id}`).catch(handleError);

/**
 * Sign up
 */
export const signUp = (user: User) =>
    axiosInstance.post<User>('/api/users/sign-up', user).catch(handleError);

/**
 * Change password
 */
export const changePassword = (changePasswordRequest: ChangePasswordRequest) =>
    axiosInstance
        .post('/api/users/password-change', changePasswordRequest)
        .catch(handleError);

/**
 * Request reset password
 */
export const requestPasswordReset = (email: string) =>
    axiosInstance
        .put('/api/users/password-reset-request', { email })
        .catch(handleError);

/**
 * Request token reset password
 */
export const changePasswordWithToken = (
    resetRequest: ChangeTokenPasswordRequest
) =>
    axiosInstance
        .post('/api/users/token-password-change', resetRequest)
        .catch(handleError);

/**
 * Gets current user's permission
 */
export const getCurrentUserPermissions = () =>
    axiosInstance
        .get<string[]>('/api/users/currentUserPermissions')
        .catch(handleError);
