import {
    LoginRequest,
    LoginResponse,
    RequestTokenResponse
} from '../../@types';
import axiosInstance from './axiosInstance';

import { handleError } from '../utils';

/**
 * Login
 */
export const login = (loginRequest: LoginRequest) =>
    axiosInstance
        .post<LoginResponse>('/api/auth/login', loginRequest)
        .catch(handleError);

/**
 * Token request
 */
export const requestToken = (
    refreshToken: string | null,
    accessToken: string | null
) =>
    axiosInstance.put<RequestTokenResponse>(
        '/api/auth/refresh',
        {
            refreshToken,
            accessToken
        },
        {
            headers: {
                Authorization: ''
            }
        }
    );

/**
 * Logout
 */
export const logout = (refreshToken: string | null) =>
    axiosInstance.put(
        '/api/auth/logout',
        {
            refreshToken
        },
        {
            headers: {
                Authorization: ''
            }
        }
    );
