import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    ListItemText,
    ListItemButton,
    Collapse,
    List,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Box
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore,
    AccountCircleOutlined,
    Settings
} from '@mui/icons-material';
import { BButton } from 'mui-bueno';
import { clearStoreLogout } from '../../../store/loginSlice';
import axiosInstance from '../../../service/Access/axiosInstance';
import { logout } from '../../../service/Access/authentication';
import { AdapterLink } from '../Utils';
import { getNavItems, Feature } from '../navigation';

interface Props {
    handleDrawerToggle: () => void;
}

const LoggedIn: React.FC<Props> = (props) => {
    const { handleDrawerToggle } = props;

    // Constants ====================================================

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const nav = getNavItems();

    // States and Handler Functions =================================

    const [accountOpen, setAccountOpen] = React.useState(false);
    const handleAccountDropdown = () => {
        setAccountOpen(!accountOpen);
    };
    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const handleSettingsDropdown = () => {
        setSettingsOpen(!settingsOpen);
    };
    const [accountAnchor, setAccountAnchor] =
        React.useState<null | HTMLElement>(null);
    const [settingsAnchor, setSettingsAnchor] =
        React.useState<null | HTMLElement>(null);
    const handleCloseMenu = () => {
        setAccountAnchor(null);
        setSettingsAnchor(null);
    };

    const handleLogout = () => {
        accountAnchor && handleCloseMenu(); // In case the menu is open
        const refreshToken = localStorage.getItem('refreshToken');
        const logoutBehavior = () => {
            axiosInstance.defaults.headers.common.Authorization = '';
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
            dispatch(clearStoreLogout());
            navigate('/');
        };
        logout(refreshToken).then(logoutBehavior).catch(logoutBehavior);
    };

    // Common Component Functions =================================

    const featureButton = (feature: Feature) => {
        return (
            <BButton
                key={`feature-${feature.name}`}
                onClick={() => {
                    navigate(feature.route);
                }}
                disableRipple
            >
                {feature.name}
            </BButton>
        );
    };

    const featureListItem = (feature: Feature, subLink: boolean) => {
        return (
            <ListItemButton
                key={`feature-${feature.name}`}
                component={AdapterLink}
                to={feature.route}
                onClick={handleDrawerToggle}
            >
                <ListItemText
                    primary={feature.name}
                    className={subLink ? 'drawer-link-sub' : 'drawer-link'}
                />
            </ListItemButton>
        );
    };

    const iconWithMenu = (
        icon: any,
        anchor: HTMLElement | null,
        setAnchor: any,
        features: Feature[],
        tooltip: string,
        append?: any
    ) => {
        return (
            <>
                <IconButton
                    color='inherit'
                    onClick={(event) => setAnchor(event.currentTarget)}
                >
                    <Tooltip title={tooltip}>{icon}</Tooltip>
                </IconButton>
                <Menu
                    className='drawer'
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleCloseMenu}
                    style={{ zIndex: 1301 }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    {features.map((feature) => (
                        <MenuItem
                            key={`feature-${feature.name}`}
                            onClick={handleCloseMenu}
                            component={Link}
                            to={feature.route}
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {feature.name}
                        </MenuItem>
                    ))}
                    {append}
                </Menu>
            </>
        );
    };

    return (
        <>
            {/* This Box is for mobile view */}
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                {nav.protected.map((feature) =>
                    featureListItem(feature, false)
                )}

                {nav.management.map((feature) => featureListItem(feature, false))}

                <ListItemButton onClick={handleSettingsDropdown}>
                    <ListItemText primary='Settings' className='drawer-link' />
                    {accountOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={settingsOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {nav.settings.map((feature) =>
                            featureListItem(feature, true)
                        )}
                    </List>
                </Collapse>
                <ListItemButton onClick={handleAccountDropdown}>
                    <ListItemText primary='Account' className='drawer-link' />
                    {accountOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={accountOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {nav.account.map((feature) =>
                            featureListItem(feature, true)
                        )}
                        <ListItemButton onClick={handleDrawerToggle}>
                            <ListItemText
                                primary='Logout'
                                onClick={handleLogout}
                                className='drawer-link-sub'
                            />
                        </ListItemButton>
                    </List>
                </Collapse>
                {nav.optional.map((feature) =>
                    featureListItem(feature, false)
                )}
            </Box>
            {/* This Box is for desktop view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {nav.protected.map((feature) => featureButton(feature))}
                {nav.management.map((feature) => featureButton(feature))}
                {nav.settings.length > 0 &&
                    iconWithMenu(
                        <Settings />,
                        settingsAnchor,
                        setSettingsAnchor,
                        nav.settings,
                        'Settings'
                    )}
                {iconWithMenu(
                    <AccountCircleOutlined />,
                    accountAnchor,
                    setAccountAnchor,
                    nav.account,
                    'Account',
                    <MenuItem
                        onClick={handleLogout}
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        Logout
                    </MenuItem>
                )}
                {nav.optional.map((feature) => featureButton(feature))}
            </Box>
        </>
    );
};

export default LoggedIn;