import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const metadata = (row: boolean) => {
    const m = new Date();
    const timestamp =
        m.getUTCMonth() +
        1 +
        '/' +
        m.getUTCDate() +
        '/' +
        m.getUTCFullYear() +
        ' ' +
        ('0' + m.getUTCHours()).slice(-2) +
        ':' +
        ('0' + m.getUTCMinutes()).slice(-2) +
        ':' +
        ('0' + m.getUTCSeconds()).slice(-2);

    return (
        <div className={row ? 'metadata-line' : 'metadata-column'}>
            <span>Version 1.0.0</span>
            <span>{timestamp}</span>
        </div>
    );
};

export const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => <Link ref={ref as any} {...props} />
);