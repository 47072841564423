import * as React from 'react';
import * as yup from 'yup';
import { Divider, Typography, Button } from '@mui/material';
import { FormikHelpers } from 'formik';
import { BEmail, BForm, BFormik, BSubmit } from 'mui-bueno';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/Logo.png';

import { showSuccessSnackbar } from '../../../store/messageSnackbarSlice';
import { requestPasswordReset } from '../../../service/Management/users';
import { handleErrorResponse } from '../../../service/utils';

const schema = yup.object<any>().shape({
    email: yup
        .string()
        .email('Must be in email format')
        .required('Email is required'),
});

const ForgotPassword: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = {
        email: '',
    };

    const handleSubmit = (
        data: { email: string },
        { setErrors }: FormikHelpers<any>
    ) => {
        requestPasswordReset(data.email)
            .then(() => {
                navigate('/login');
                dispatch(showSuccessSnackbar('Password reset email sent'));
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    setStatus: setErrors,
                    prefix: 'Could not request password reset: ',
                });
            });
    };

    return (
        <div className='access-form'>
            <BFormik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={schema}
            >
                <BForm>
                    <img src={logo} width="100%" alt="Logo for project" />
                    <div className='card'>
                        <Typography variant="h2" align="center" >
                            Forgot Password
                        </Typography>
                        <Divider style={{ backgroundColor: '#F0F0F0' }} variant='fullWidth' />
                        <Typography align="center" paddingTop={1} >
                            Enter email to receive link to change password
                        </Typography>
                        <BEmail noMP name="email" variant='filled' style={{ paddingBottom: '10px' }} />
                        <Divider style={{ backgroundColor: '#F0F0F0' }} />
                        <div className='right-buttons'>
                            <Button
                                name="cancel"
                                className="cancel-button"
                                style={{ marginTop: '15px' }}
                                onClick={() => navigate('/login')}
                            >
                                Cancel
                            </Button>
                            <BSubmit
                                name="resetPassword"
                                className="password-button"
                                style={{ marginTop: '15px' }}
                            >
                                Send Link
                            </BSubmit>
                        </div>
                    </div>
                </BForm>
            </BFormik>
        </div >
    );
};

export default ForgotPassword;