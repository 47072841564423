import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { CssBaseline } from '@mui/material';

import Layout from './common/Layout/Layout';
import NotFound from './common/Routes/NotFound';
import PublicRoute from './common/Routes/PublicRoute';
import ProtectedRoute from './common/Routes/ProtectedRoute';
import IdleTimeout from './common/IdleTimeout/IdleTimeout';
import TokenRefresher from './common/IdleTimeout/TokenRefresher';
import MessageSnackbar from './common/MessageSnackbar/MessageSnackbar';

import Login from './features/Access/Login/Login';
import UserList from './features/Management/UserList/UserList';
import RoleList from './features/Management/RoleList/RoleList';
import RoleDetail from './features/Management/RoleDetail/RoleDetail';
import UserDetail from './features/Management/UserDetail/UserDetail';
import ForgotPassword from './features/Access/PasswordReset/ForgotPassword';
import ChangePassword from './features/Access/ChangePassword/ChangePassword';
import ProjectMetaDetail from './features/ProjectMetaDetail/ProjectMetaDetail';
import ComparisonView from './features/Comparison/ComparisonView';
import TableMetaDetail from './features/TableMetaDetail/TableMetaDetail';
import DatabaseMetaDetail from './features/DatabaseMetaDetail/DatabaseMetaDetail';
import SchemaMetaDetail from './features/SchemaMetaDetail/SchemaMetaDetail';
import ProjectList from './features/ProjectList/ProjectList';
import TagList from './features/Tags/TagList';
import TagDetail from './features/Tags/TagDetails';
import ColumnMetaDetail from './features/ColumnMetaDetail/ColumnMetaDetail';

const App: React.FC = () => {
    return (
        <>
            <CssBaseline />
            <Layout>
                <Routes>
                    <Route path='/' element={<PublicRoute />}>
                        <Route path='' element={<Login />} />
                        <Route path='login' element={<Login />} />
                        <Route path='user/password/forgot' element={<ForgotPassword />} />
                    </Route>
                    <Route path='/' element={<ProtectedRoute />}>
                        <Route path='project' element={<ProjectList />} />
                        <Route path='/role' element={<RoleList />} />
                        <Route path='/role/:roleId' element={<RoleDetail />} />
                        <Route path='/user' element={<UserList />} />
                        <Route path='/user/:userId' element={<UserDetail />} />
                        <Route path='/tag' element={<TagList />} />
                        <Route path='/tag/:tagId' element={<TagDetail />} />
                        <Route path='project/:projectId' element={<ProjectMetaDetail />} />
                        <Route path='table/:tableId' element={<TableMetaDetail />} />
                        <Route path='database/:databaseId' element={<DatabaseMetaDetail />} />
                        <Route path='schema/:schemaId' element={<SchemaMetaDetail />} />
                        <Route path='user/password/change' element={<ChangePassword />} />
                        <Route path='comparison-view' element={<ComparisonView />} />
                        <Route path={'column/:columnId'} element={<ColumnMetaDetail/>} />
                    </Route>
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Layout>
            <MessageSnackbar />
            <IdleTimeout />
            <TokenRefresher />
        </>
    );
};

export default App;
