import { AxiosError } from 'axios';

import axiosInstance from './axiosInstance';
import store from '../../store/store';
import { clearStoreLogout } from '../../store/loginSlice';
import { showErrorSnackbar } from '../../store/messageSnackbarSlice';
import { clearTokens } from '../../features/Access/Login/localStorageUtil';

const setupAxiosInterceptors = () => {
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        (error: AxiosError) => {
            // Error received from service
            if (error.response) {
                if (error.response.status === 401) {
                    // unauthorized access; will try to log user out
                    axiosInstance.defaults.headers.common.Authorization = '';
                    clearTokens();
                    store.dispatch(clearStoreLogout());
                    store.dispatch(showErrorSnackbar('Unauthorized Access'));
                }
            }
            // No error response from service
            else {
                store.dispatch(
                    showErrorSnackbar(
                        'Network connection error, please try again later.'
                    )
                );
            }

            return Promise.reject(error);
        }
    );
};

export default setupAxiosInterceptors;
