import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useLoggedInUser from '../hooks/useLoggedInUser';

const PublicRoute: React.FC = () => {
    const user = useLoggedInUser();

    return user.id == undefined ? <Outlet /> : <Navigate to='/project' replace />;
};

export default PublicRoute;
