import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ParentIdState {
    id: number | null;
}

export const initialState: ParentIdState = {
    id: null // no parent has been selected initially, set to null
};


const parentIdSlice = createSlice({
    name: 'parentId',
    initialState,
    reducers: {
        setParentId: (state, action: PayloadAction<number | null>) => {
            state.id = action.payload;
        }
    },
});


export const { setParentId } = parentIdSlice.actions;

export default parentIdSlice.reducer;