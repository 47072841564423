import { Box, Drawer } from '@mui/material';
import * as React from 'react';
import ProjectTree from './ProjectTree';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../store/sidebarSlice';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Logo from './/SC_logo_white.png';
import store from '../../store/store';
import { metadata } from '../../common/Layout/Utils';


const ProjectExplorer: React.FC = () => {
    const globalAnchor = 'left';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const drawerRef = React.useRef<HTMLDivElement>(null);
    const treeRef = React.useRef<HTMLDivElement>(null);
    const footerRef = React.useRef<HTMLDivElement>(null);
    const currState = store.getState();

    const [contextMenuPosition, setContextMenuPosition] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const toggleDrawer = () => {
        dispatch(toggleSidebar()); // Need to call the dispatch with toggleSidebar so that we can set the variable expanded to whatever it wasn't before
    };

    const handleRightClick = (event: React.MouseEvent) => {
        if ((drawerRef.current && drawerRef.current.contains(event.target as Node)) && //if you click in whitespace of project explorer
            (treeRef.current && !treeRef.current.contains(event.target as Node)) &&
            (footerRef.current && !footerRef.current.contains(event.target as Node))) {
            event.preventDefault();
            setContextMenuPosition(
                contextMenuPosition === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : null,
            );
        }
        // else if ((drawerRef.current && !drawerRef.current.contains(event.target as Node)) && 
        //         (treeRef.current && !treeRef.current.contains(event.target as Node)) && 
        //         (footerRef.current && !footerRef.current.contains(event.target as Node)) &&
        //         (tableRef.current && tableRef.current.contains(event.target as Node))) {
                
        // }
    }

    const handleClose = () => {
        setContextMenuPosition(null);
    };

    const list = () => (
        <Box
            sx={{ width: '100%', height: '100%' }}
            role="presentation"
            ref={treeRef}
        >
            <ProjectTree />
        </Box>
    );


    return (
        <div className="project_explorer">
            <Menu
                open={contextMenuPosition !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenuPosition !== null
                        ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={() => {
                    navigate('project/new');
                    handleClose();
                }}> Create new project</MenuItem>
            </Menu>
            <React.Fragment key={globalAnchor} >
                <Box className='to-open-tab' onClick={toggleDrawer} data-testid='openExplorer'>
                    <ChevronRightIcon className='right-arrow' fontSize='large' />
                </Box>
                <div className='sidebar-drawer'>
                    <Drawer
                        className='draweRef'
                        data-testid='openDrawer'
                        anchor={globalAnchor}
                        open={currState.sidebar.expanded}
                        variant='persistent'
                        onClose={toggleDrawer}
                        ref={drawerRef}
                        onContextMenu={handleRightClick}
                        sx={{
                            '& .MuiDrawer-paper': {
                                //Changing this width moves bar out, but does not squish screen with it,
                                // Changing $sidebarexpanded in Layout.scss does affect padding
                                width: 304,
                                overflowX: 'hidden',
                                boxSizing: 'border-box',
                            },
                        }}
                        PaperProps={{
                            sx: {
                                border: 'none',
                                backgroundColor: 'transparent',
                                paddingTop: 6
                            }
                        }}
                    >
                        <div className='wrapper'>
                            <div className='project-explorer-header'>
                                Project Explorer
                            </div>
                            <div className='treeRef'>
                                {list()}
                            </div>
                            <div className='empty-sidebar' />
                            <div className='sidebar-footer' ref={footerRef}>
                                <img src={Logo} alt='Logo' />
                                {metadata(false)}
                            </div>
                        </div>

                        <Box className='tab-to-close-sidebar' onClick={toggleDrawer} onContextMenu={handleRightClick} data-testid='closeExplorer'>
                            <ChevronLeftIcon fontSize='large' />
                        </Box>
                    </Drawer>
                </div>
            </React.Fragment>
        </div>
    );


}

export default ProjectExplorer;
