import React, { useState, ChangeEvent, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Card, Button, Typography, Autocomplete } from '@mui/material';
import { BConfirmButton } from 'mui-bueno';
import DeleteIcon from '@mui/icons-material/Delete';

import { AccountTree as AccountTreeIcon, Save as SaveIcon, Schema as SchemaIcon, Search as SearchIcon, Storage as StorageIcon, TableView as TableViewIcon } from '@mui/icons-material';

import MetaList from './MetaList/MetaList';
import {
    ProjectMeta,
    DatabaseMeta,
    SchemaMeta,
    TableMeta,
    ColumnMeta,
    Tag,
} from '../../@types';
import { getAllTags } from '../../service/Management/tags';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';

import { useDispatch } from 'react-redux';
import { setPendingUpdate } from '../../store/sidebarSlice';
import ConstraintList from '../../features/ConstraintList/ConstraintList';
import { useLocation } from 'react-router-dom';

export type Meta = ProjectMeta | DatabaseMeta | SchemaMeta | TableMeta | ColumnMeta;

export const MAP_NAMES = {
    schema: 'Table Name',
    project: 'Database Name',
    database: 'Schema Name',
    table: 'Column Name',
};


interface MetaDetailProps {
    id: string;
    level: 'project' | 'database' | 'schema' | 'table' | 'column';
    data: Meta;
    setData: React.Dispatch<React.SetStateAction<Meta>>;
    onCreate: () => void;
    onUpdate: () => void;
    onDelete: () => void;
    childrenData: Meta[];
    updateChild: (childMeta: Meta) => void;
    createChild: (childMeta: Meta) => void;
    deleteChild: (childId: number) => void;
}

const MetaDetail = (props: MetaDetailProps) => {
    const dispatch = useDispatch();

    const level = props.level;
    const [data, setData] = useState<Meta[]>(props.childrenData);
    const [showSearchBar, setShowSearchBar] = useState(false);

    const [allTags, setAllTags] = useState<Tag[]>([]);

    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

    const isDatabaseMeta = (meta: Meta): meta is DatabaseMeta => {
        return (meta as DatabaseMeta).type !== undefined && (meta as DatabaseMeta).url !== undefined;
    }

    const createDisabled = !props.data.name;

    const SaveOrCreateButton: React.FC = () => {
        return (
            <>
                {props.id !== 'new' ?
                    < Button
                        startIcon={<SaveIcon />}
                        variant="contained"
                        className="save-button"
                        onClick={() => onUpdate()}
                        disabled={createDisabled}
                        style={{
                            margin: '10px'
                        }}
                    >
                        {'Save ' + level.charAt(0).toUpperCase() + level.slice(1)}
                    </Button> :
                    <Button
                        startIcon={<SaveIcon />}
                        variant="contained"
                        className="save-button"
                        onClick={() => onCreate()}
                        disabled={createDisabled}
                        style={{
                            margin: '10px'
                        }}
                    >
                        {'Create ' + level.charAt(0).toUpperCase() + level.slice(1)}
                    </Button>
                }
            </>
        )
    }

    const DeleteButton: React.FC = () => {
        return (
            <BConfirmButton
                button={'Delete ' + level.charAt(0).toUpperCase() + level.slice(1)}
                objectType={props.level}
                objectName={props.data.name}
                onConfirm={onDelete}
                buttonProps={{
                    color: 'error',
                    startIcon: <DeleteIcon />,
                    variant: 'contained',
                    className: 'save-button'
                }} />
        )
    }

    React.useEffect(() => {
        setData(props.childrenData);
    }, [props.childrenData]);

    React.useEffect(() => {
        getAllTags().then(res => {
            setAllTags(res.data);
        }).catch(err => {
            console.error(err);
        });
    }, [])

    React.useEffect(() => {
        if (props.data.tagIds) {
            const tempTagState = [];
            for (let i = 0; i < allTags.length; i++) {
                for (let j = 0; j < props.data.tagIds.length; j++) {
                    if (allTags[i].id === props.data.tagIds[j]) {
                        tempTagState.push(allTags[i]);
                    }
                }
            }
            setSelectedTags(tempTagState);
        } else if (props.id == 'new') {
            setSelectedTags([]);
        }
    }, [allTags, props.data.id])

    const handleSearchBarToggle = () => {
        setShowSearchBar(!showSearchBar);
    };
    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const filteredData = props.childrenData.filter((row) =>
            Object.values(row).some((val) => {
                if (typeof val === 'string') {
                    return val.toLowerCase().includes(value.toLowerCase());
                }
                if (typeof val === 'number') {
                    return val.toString().toLowerCase().includes(value.toLowerCase());
                }
                return false;
            })
        );
        setData(filteredData);
    };

    const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        props.setData(prev => ({ ...prev, description: event.target.value }));
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        props.setData(prev => ({ ...prev, name: event.target.value }));
    }

    const handleChangeDbType = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        props.setData(prev => ({ ...prev, type: event.target.value }));
    }

    const handleChangeDbUrl = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        props.setData(prev => ({ ...prev, url: event.target.value }));
    }

    const onCreate = () => {
        props.onCreate();
        dispatch(setPendingUpdate());
    }

    const onUpdate = () => {
        props.onUpdate();
        dispatch(setPendingUpdate());
    }

    const onDelete = () => {
        props.onDelete();
        dispatch(setPendingUpdate());
    }

    const handleChangeTags = (value: Tag[]) => {
        setSelectedTags(value);
        const tempTags = value.map((val) => val.id);
        props.setData(prev => ({ ...prev, tagIds: tempTags as number[] }));
    }

    const location = useLocation();
    const [locationKey, setLocationKey] = useState(location.key);
    useEffect(() => { setLocationKey(location.key); }, [location]);

    return (
        <div className='page-body max-lg full-size'>
            <div className="header-container">
                <Breadcrumb key={locationKey} type={level} id={props.data.id} />
                <Card className="list-header" elevation={3}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {level === 'project' && <AccountTreeIcon style={{ fontSize: '50px', verticalAlign: 'middle', color: '#213353' }} />}
                            {level === 'database' && <StorageIcon style={{ fontSize: '50px', verticalAlign: 'middle', color: '#213353' }} />}
                            {level === 'schema' && <SchemaIcon style={{ fontSize: '50px', verticalAlign: 'middle', color: '#213353' }} />}
                            {level === 'table' && <TableViewIcon style={{ fontSize: '50px', verticalAlign: 'middle', color: '#213353' }} />}
                            {props.id !== 'new' ? (
                                <>
                                    <Typography variant="h4" style={{ marginLeft: '10px', marginBottom: 0, marginRight: '4px' }}>
                                        {level.charAt(0).toUpperCase() + level.slice(1) + ':'}
                                    </Typography>
                                    <div>
                                        <TextField
                                            required
                                            fullWidth
                                            value={props.data.name}
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {
                                                    fontSize: '30px',
                                                    verticalAlign: 'middle',
                                                    marginTop: 0,
                                                    paddingTop: '1px',
                                                },
                                            }}
                                            onChange={(event) => handleChangeName(event)}
                                        />
                                    </div>
                                </>
                            ) : (
                                <TextField
                                    fullWidth
                                    label='Name'
                                    placeholder={`new ${level}`}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            fontSize: '30px',
                                            verticalAlign: 'middle',
                                            marginTop: 0,
                                            paddingTop: '5px',
                                        },
                                    }}
                                    onChange={(event) => handleChangeName(event)}
                                />
                            )}
                        </div>
                        {showSearchBar ? (
                            <TextField
                                autoFocus
                                variant="outlined"
                                size="small"
                                placeholder="Search by name"
                                onChange={handleSearchInputChange}
                                onBlur={handleSearchBarToggle}
                            />
                        ) : (
                            <Button
                                startIcon={<SearchIcon />}
                                variant="contained"
                                className="filter-button"
                                onClick={handleSearchBarToggle}
                            >
                                Filter
                            </Button>
                        )}
                    </div>
                </Card>
            </div>

            <Card>
                <div className="description-content">
                    {/* <Typography variant="body1">This is the description of the table.</Typography> */}
                    <div style={{ padding: '10px', paddingTop: '15px' }}>
                        <TextField
                            label='Description'
                            key={props.data.id ? 'descNotLoaded' + props.data.id : 'descLoaded' + props.data.id}
                            fullWidth
                            id="outlined-multiline-flexible"
                            multiline
                            minRows={4}
                            variant='outlined'
                            onChange={(event) => handleChangeDescription(event)}
                            defaultValue={props.id == 'new' ? '' : props.data.description}
                            placeholder='Description'
                        />
                        <Autocomplete
                            multiple
                            value={selectedTags}
                            sx={{ marginTop: '10px', width: '40%' }}
                            id="tags-standard"
                            options={allTags}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => handleChangeTags(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Tags'
                                />
                            )}
                        />
                        {level == 'database' &&
                            <>
                                <TextField
                                    label='Type'
                                    key={props.data.id ? 'typeNotLoaded' + props.data.id : 'typeLoaded' + props.data.id}
                                    onChange={(event) => handleChangeDbType(event)}
                                    variant='outlined'
                                    defaultValue={props.id == 'new' ? ' ' : isDatabaseMeta(props.data) ? props.data.type : ''}
                                    style={{ marginTop: '10px', paddingRight: '5px' }}
                                />
                                <TextField
                                    label='URL'
                                    key={props.data.id ? 'urlNotLoaded' + props.data.id : 'urlLoaded' + props.data.id}
                                    onChange={(event) => handleChangeDbUrl(event)}
                                    variant='outlined'
                                    defaultValue={props.id == 'new' ? ' ' : isDatabaseMeta(props.data) ? props.data.url : ''}
                                    style={{ marginTop: '10px', paddingRight: '5px' }}
                                />
                            </>

                        }
                    </div>
                    <SaveOrCreateButton />
                    {props.id == 'new' ? null : <DeleteButton />}
                </div>
            </Card>

            {props.id == 'new' ? null :
                props.level === 'column' ? <>  <br /> <Card> <ConstraintList data={props.data} /> </Card> </>
                    :
                    <>
                        <br />
                        <Card>
                            <div className="table-content" data-testid="table-component">
                                <MetaList id={props.data.id} rows={data} createChild={props.createChild} updateChild={props.updateChild} deleteChild={props.deleteChild} level={level} />
                            </div>
                        </Card>
                    </>


            }
        </div>
    );

};
export default MetaDetail;
