import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axiosInstance from '../../service/Access/axiosInstance';
import useLoggedInUser from '../hooks/useLoggedInUser';
import usePermissions from '../hooks/usePermissions';
import NotFound from './NotFound';

interface Props {
    apiPermission?: string;
}

const ProtectedRoute: React.FC<Props> = (props) => {
    const { apiPermission } = props;

    const user = useLoggedInUser();

    const accessToken = localStorage.getItem('accessToken');

    // On refresh, if the authorization header is not set, access from local storage
    if (!axiosInstance.defaults.headers.common.Authorization && accessToken) {
        axiosInstance.defaults.headers.common.Authorization =
            'Bearer ' + accessToken;
    }

    // If no permission is passed in, use an empty string which will give the user access to the route
    const hasAccess = usePermissions(apiPermission ?? '');

    if (user.id) {
        return hasAccess ? <Outlet /> : <NotFound />;
    } else {
        return <Navigate to='/login' replace />;
    }
};

export default ProtectedRoute;
