import * as React from 'react';
import MetaDetail, { Meta } from '../../common/MetaDetail/MetaDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { TableMeta } from '../../@types';
import { createTableMeta, deleteTableMeta, getTableMeta, updateTableMeta } from '../../service/Meta/tableMeta';
import { getAllByTableId } from '../../service/Meta/columnMeta';
import { createColumnMeta, deleteColumnMeta, updateColumnMeta } from '../../service/Meta/columnMeta';
import { ColumnMeta } from '../../@types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ParentIdState } from '../../store/parentIdSlice';

const initialDataState: TableMeta = {
    id: undefined,
    name: '',
    description: '',
    schemaId: 0
};

interface RouteParams extends Record<string, string> { tableId: string; }

const TableMetaDetail = () => {
    const navigate = useNavigate();
    const { id: parentId } = useSelector<RootState, ParentIdState>(
        (store) => store.parentId
    );
    const [children, setChildren] = React.useState<Meta[]>([]);
    const [data, setData] = React.useState<Meta>(initialDataState)

    const onCreate = async () => {
        //createTableMeta()
        try {
            const res = await createTableMeta({ name: data.name, description: data.description, schemaId: parentId ?? 0, tagIds: data.tagIds });

            setMetaId(res.data.id);
            navigate(`../table/${res.data.id}`);

        } catch (error) {
            console.error('Error while creating:', error);
        }
    }

    const onDelete = () => {
        try {
            deleteTableMeta(Number(metaId));
            navigate(`../table/${(data as TableMeta).schemaId}`);
        } catch (error) {
            console.error('error during deletion')
        }

    }

    const onUpdate = async () => {
        //console.log('update')
        try {
            //const table: TableMeta = data as TableMeta;

            const res = await updateTableMeta(data as TableMeta);

            setMetaId(res.data.id);
            navigate(`../table/${res.data.id}`);

        } catch (error) {
            console.error('Error while updating: ', error)
        }
    }

    /**
    * Adds a child to the editable list of children
    * Page rerenders including new child
    * @param child the child to add
    */
    const onCreateChild = async (child: Meta) => {
        //console.log(child);
        const column: ColumnMeta = child as ColumnMeta;
        column.id = undefined;
        column.tableId = Number(metaId);

        try {
            await createColumnMeta(column);
            //console.log(res);
            //TODO maybe this should not refetch all the data?
            getAllByTableId(Number(metaId)).then((res) => {
                const columns: ColumnMeta[] = res.data;
                setChildren(columns as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while creating child column')
        }

    }


    const onUpdateChild = async (child: Meta) => {

        try {
            await updateColumnMeta(child as ColumnMeta);
            //console.log(res);
            getAllByTableId(Number(metaId)).then((res) => {
                const columns: ColumnMeta[] = res.data;
                setChildren(columns as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while updating child column')
        }
    }

    const onDeleteChild = async (childId: number) => {
        try {

            await deleteColumnMeta(Number(childId));
            getAllByTableId(Number(metaId)).then((res) => {
                const columns: ColumnMeta[] = res.data;
                setChildren(columns as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while deleting child column')
        }
    }


    const { tableId } = useParams<RouteParams>();
    //This will become a state that allows the data prop to be populated
    const [metaId, setMetaId] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (tableId === 'new') {
            setMetaId(undefined);
            setChildren([]);
            setData(initialDataState);
        }
        else {
            setMetaId(Number(tableId));
        }
    }, [tableId])

    React.useEffect(() => {
        if (metaId === undefined) {
            setData(initialDataState); setChildren([]);
        } else {
            getTableMeta(Number(metaId)).then((res) => {
                setData(res.data);
            })
            getAllByTableId(Number(metaId)).then((res) => {
                const columns: ColumnMeta[] = res.data;
                const rowData: Meta[] = [];
                for (let i = 0; i < columns.length; i++) {
                    const curr: ColumnMeta = columns[i]; rowData.push(curr as Meta);
                }
                setChildren(rowData)
            })
        }

    }, [metaId]);

    return (
        <div>
            {<MetaDetail id={String(tableId)} level="table" onCreate={onCreate} onDelete={onDelete} setData={setData} data={data} onUpdate={onUpdate} childrenData={children} createChild={onCreateChild} updateChild={onUpdateChild} deleteChild={onDeleteChild} />}

        </div>
    );


};

export default TableMetaDetail;
