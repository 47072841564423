import { useSelector } from 'react-redux';

import { RootState } from '../../store/store';

const usePermissions = (permission: string) => {
    /*
        This is necessary for ProtectedRoute because passing in a permission
        is an optional prop. If an empty string is present then that means the
        user should have access to the route as long as they are logged in.
    */
    if (permission === '') return true;

    const userPerms = useSelector<RootState, string[]>(
        (store) => store.login.permissions
    );

    return userPerms.includes(permission);
};

export default usePermissions;
