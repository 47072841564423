import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { AxiosError } from 'axios';
import logo from '../Assets/Logo.png';


import { BEmail, BForm, BFormik, BPassword, BSubmit } from 'mui-bueno';
import { FormikErrors, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { DecodedToken, LoginRequest, User } from '../../../@types';
import { login } from '../../../service/Access/authentication';
import axiosInstance from '../../../service/Access/axiosInstance';
import { loginSuccess } from '../../../store/loginSlice';
import { handleErrorResponse } from '../../../service/utils';
import { getCurrentUserPermissions } from '../../../service/Management/users';
import { setTokens } from './localStorageUtil';

const schema = yup.object<LoginRequest>().shape({
    email: yup
        .string()
        .email('Must be in email format')
        .required('Email is required'),
    password: yup.string().required('Password is required')
});

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: ''
    };

    const handleLoginError = (
        err: AxiosError,
        setErrors: (errors: FormikErrors<LoginRequest>) => void
    ) => {
        handleErrorResponse(err, dispatch, {
            setStatus: setErrors,
            prefix: 'Could not login: '
        });
    };

    const handleSuccessLogin = (
        accessToken: string,
        refreshToken: string,
        permissions: string[]
    ) => {
        setTokens(accessToken, refreshToken);

        // NOTE: if you get error on decoding "Invalid token specified: Unexpected token \x1F in JSON at position 0",
        // check the service to make sure that it does not compress the token with GZIP
        const decodedToken: DecodedToken = jwt_decode(accessToken);
        const user: User = {
            id: decodedToken.id,
            firstName: decodedToken.firstName,
            lastName: decodedToken.lastName,
            email: decodedToken.sub,
            roleIds: []
        };
        dispatch(loginSuccess({ user, permissions, accessToken }));
        navigate('/dashboard');
    };

    const handleSubmit = (
        data: LoginRequest,
        { setErrors }: FormikHelpers<LoginRequest>
    ) => {
        login(data)
            .then((res) => {
                const accessToken = res.data.accessToken;
                const refreshToken = res.data.refreshToken;

                axiosInstance.defaults.headers.common.Authorization =
                    'Bearer ' + accessToken;

                getCurrentUserPermissions()
                    .then((permRes) => {
                        const permissions = permRes.data;
                        handleSuccessLogin(
                            accessToken,
                            refreshToken,
                            permissions
                        );
                    })
                    .catch((permissionErr) => {
                        handleLoginError(permissionErr, setErrors);
                    });
            })
            .catch((loginErr) => {
                handleLoginError(loginErr, setErrors);
            });
    };
    return (
        <div className='access-form'>

            <BFormik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={schema}
            >


                <BForm>
                    <img src={logo} width="100%" alt="Logo for project" />
                    <div className='card'>
                        <BEmail noMP name='email' variant='filled' />
                        <BPassword noMP name='password' variant='filled' />
                        <BSubmit name='login'>Login</BSubmit>
                        <div className='option-links'> 
                            <Link to='/user/password/forgot'>
                                Forgot Password
                            </Link> 
                        </div>
                    </div>
                </BForm>
            </BFormik >
        </div>
    );
};

export default Login;
