import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SaveIcon from '@mui/icons-material/Save';
import {
    BButton,
    BConfirmButton,
    BError,
    BForm,
    BFormik,
    BGrid,
    BSubmit,
    BT,
    BTextField
} from 'mui-bueno';


import { handleErrorResponse } from '../../service/utils';
import {
    getTag,
    updateTag,
    createTag,
    deleteTag
} from '../../service/Management/tags';
import { showSuccessSnackbar } from '../../store/messageSnackbarSlice';
import { Tag } from '../../@types';

const schema = yup.object<Tag>().shape({
    name: yup.string().required('Name is required')
});

interface RouteParams extends Record<string, string> {
    tagId: string; // new or id
}
const TagDetails: React.FC = () => {
    const { tagId } = useParams<RouteParams>();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [tag, setTag] = React.useState<Tag>({
        id: undefined,
        name: '',
        description: '',
        url: '',
        code: ''
    });

    React.useEffect(() => {
        if (tagId === 'new') return;

        getTag(Number(tagId))
            .then((res) => {
                setTag(res.data);
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Tag: '
                });
            });
    }, [tagId]);

    const handleSubmit = (data: Tag, { setErrors }: FormikHelpers<Tag>) => {
        if (data.id) {
            updateTag(data)
                .then((res) => {
                    setTag(res.data);
                    dispatch(showSuccessSnackbar(`${res.data.name} updated`));
                })
                .catch((err) => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not update Tag: '
                    });
                });
        } else {
            createTag(data)
                .then((res) => {
                    navigate(`/tag/${res.data.id}`);
                    dispatch(showSuccessSnackbar(`${res.data.name} created`));
                })
                .catch((err) => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not update create Tag: '
                    });
                });
        }
    };

    const handleConfirmDelete = () => {
        if (!tag.id) return;
        deleteTag(tag.id)
            .then(() => {
                dispatch(showSuccessSnackbar(`${tag.name} deleted`));
                navigate('/tag');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not delete Tag: '
                });
            });
    };

    return (
        <BFormik
            initialValues={tag}
            onSubmit={handleSubmit}
            validationSchema={schema}
            enableReinitialize
        >
            <div className='page'>
                <div className='page-body max-md full-size'>
                    <BButton
                        variant='text'
                        onClick={() => navigate('/tag')}
                        startIcon={<KeyboardBackspaceIcon />}
                        style={{ margin: '15px 0px 10px' }}
                    >
                        Back
                    </BButton>
                    <BForm>
                        <Card className='management-form'>
                            <CardHeader
                                title={
                                    <BT variant='h4' align='center'>
                                        {tag.id ? `${tag.name}` : 'Add Tag'}
                                        <BError id='general' name='general' />
                                    </BT>
                                }
                            />
                            <CardContent>
                                <BGrid container spacing={1} alignment='center'>
                                    <BTextField
                                        name='name'
                                        variant='filled'
                                        xs={12}
                                        noMP
                                    />
                                    <BTextField
                                        name='description'
                                        multiline
                                        variant='filled'
                                        xs={12}
                                        noMP
                                    />
                                    <BTextField
                                        name='url'
                                        multiline
                                        variant='filled'
                                        xs={12}
                                        noMP
                                    />
                                    <BTextField
                                        name='code'
                                        multiline
                                        variant='filled'
                                        xs={12}
                                        noMP
                                        rows={5}
                                    />
                                </BGrid>
                            </CardContent>
                            <CardActions>
                                {tag.id ? (
                                    <BConfirmButton
                                        objectType='Tag'
                                        objectName={`${tag.name}`}
                                        onConfirm={handleConfirmDelete}
                                    />
                                ) : (
                                    <div />
                                )}
                                <BSubmit startIcon={<SaveIcon />}>Save</BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </div>
            </div>
        </BFormik>
    );
};

export default TagDetails;
