import * as React from 'react';
import Typography from '@mui/material/Typography';
import { ColumnMeta, Transformation } from '../../@types';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { getColumnMeta } from '../../service/Meta/columnMeta';
import { deleteTransformation, updateTransformation, createTransformation } from '../../service/Meta/transformation';

interface Props {
    mode: string,
    selectedLegacy: ColumnMeta[],
    selectedModern: ColumnMeta[],
    editMode: boolean,
    changeModernColumns: (column: ColumnMeta[]) => void,
    changeLegacyColumns: (column: ColumnMeta[]) => void,
    closeMapping: () => void, // Callback function for the cancel button to close the sidebar
    revertSelected: () => void,
    setToDescription: () => void, // Set to description view on save
    transformations: Transformation[],
    editMappingsButton: () => void,
    cancelMappingsButton: () => void,
    expandAndHighlight: (transformation : Transformation) => void,
    createMode: boolean,
}

const TransformationDetail: React.FC<Props> = (props) => {
    // These are the two that will actually be edited and passed back up via callback function
    const selectedLegacy: ColumnMeta[] = props.selectedLegacy;
    const selectedModern: ColumnMeta[] = props.selectedModern;
    const transformations: Transformation[] = props.transformations;
    const viewOnly: boolean = props.mode === 'update'
    // These two will act as outer "shells" which won't disappear when you uncheck them
    const [activeLegacy, setActiveLegacy] = React.useState<ColumnMeta[]>([]);
    const [activeModern, setActiveModern] = React.useState<ColumnMeta[]>([]);
    const [transformation, setTransformation] = React.useState<Transformation>({
        id: undefined,
        description: '',
        sourceIds: [],
        destinationIds: [],
        code: ''
    });
    const [description, setDescription] = React.useState('');

    React.useEffect(() => {
        setTransformation(transformations[0])
    }, [transformations])

    React.useEffect(() => {
        const getData = async () => {

            setActiveLegacy([]);
            setActiveModern([]);

            try {
                if (transformation) {
                    const sourceList: ColumnMeta[] = [];
                    const destinationList: ColumnMeta[] = [];
                    for (const id of transformation.sourceIds) {
                        const response = await getColumnMeta(id);
                        const column = response.data;
                        sourceList.push(column)
                    }
                    for (const id of transformation.destinationIds) {
                        const response = await getColumnMeta(id);
                        const column = response.data;
                        destinationList.push(column)
                    }
                    props.expandAndHighlight(transformation);
                    setActiveLegacy(sourceList);
                    setActiveModern(destinationList);
                    setDescription(transformation.description);

                } else {
                    props.changeLegacyColumns([])
                    props.changeModernColumns([])
                    setDescription('');
                }
            } catch (error) {
                console.error('Error fetching transformation ids: ', error);
            }
        }
        getData()
    }, [transformation])

    React.useEffect(() => {
        const activeLegacyRender = [...activeLegacy] // Sets activeLegacyReader to previous activeLegacy contents. First time around it enters this for loop and gets instantiated with everything in it.
        for (const column of props.selectedLegacy) {
            if (!activeLegacy.some(current => current.id === column.id)) {
                activeLegacyRender.push(column) // Adds columns from the newly passed in propped to activeLegacy if it's not already there
            }
        }
        setActiveLegacy(activeLegacyRender); // Need to use a separate list because the set function is async, so can't just do setActiveLegacy under that if statement
    }, [selectedLegacy.length])

    React.useEffect(() => {
        setActiveModern(props.selectedModern);
        setActiveLegacy(props.selectedLegacy);
        setDescription('');
    }, [props.editMode])

    React.useEffect(() => {
        const activeModernRender = [...activeModern];
        for (const column of props.selectedModern) {
            if (!activeModern.some(current => current.id === column.id)) {
                activeModernRender.push(column)
            }
        }
        setActiveModern(activeModernRender);
    }, [selectedModern.length])

    const handleTransformationChange = (event: SelectChangeEvent<Transformation>) => {
        setTransformation(event.target.value as Transformation);
    }
    // We don't touch activeLegacy at all in this. That is all handled by the useEffects since we are technically ONLY ADDING to activeLegacy
    const handleToggle = (column: ColumnMeta, isLegacy: boolean) => {
        if (isLegacy) {
            if (props.selectedLegacy.includes(column)) {
                props.changeLegacyColumns(props.selectedLegacy.filter(n => n.id !== column.id)); // Removes the column if it's already in it since that means it was already checked, passes it back up after
            }
            else {
                props.changeLegacyColumns(props.selectedLegacy.concat(column)); // Adds the column to selectedLegacy since in this case it was unchecked before we hit this function, passes it back up after
            }
        }
        else {
            if (props.selectedModern.includes(column)) {
                props.changeModernColumns(props.selectedModern.filter(n => n.id !== column.id));
            }
            else {
                props.changeModernColumns(props.selectedModern.concat(column));
            }
        }
    };

    //Save button will remove unchecked items from summary
    //This will be changed to a backend call
    const onSave = () => {
        const updatedTransformation: Transformation = {
            id: transformation.id,
            sourceIds: selectedLegacy.map((source) => source.id) as number[],
            destinationIds: selectedModern.map((modern) => modern.id) as number[],
            description: description,
            code: transformation.code,
        }

        updateTransformation(updatedTransformation)
            .then(() => {
                props.revertSelected();
                props.closeMapping();
            })
            .catch((error) => {
                throw new Error('Error updating transformation.', error);
            });
    }

    const onCreate = () => {
        // setDescription('');
        const sourceIds: number[] = selectedLegacy.map(col => col.id) as number[];
        const destinationIds: number[] = selectedModern.map(col => col.id) as number[];
        createTransformation({ sourceIds: sourceIds, destinationIds: destinationIds, code: '', description: description}).then(() => {

            props.revertSelected();
            props.closeMapping();
        })
            .catch((error) => {
                throw new Error('Error updating transformation.', error);
            });
    }

    const handleDescriptionChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setDescription(event.target.value);
    }

    const onCancel = () => {
        props.cancelMappingsButton();
    }

    const onDeleteTransformation = () => {
        if (transformation && transformation.id !== undefined) {
            deleteTransformation(transformation.id)
                .then(() => {
                    props.closeMapping();
                    props.revertSelected();
                })
                .catch((error) => {
                    throw new Error('Error deleting transformation:', error);
                });
        }
        else {
            throw new Error('Transformation ID is undefined or invalid.');
        }
    }

    const [legacySearchQuery, setLegacySearchQuery] = React.useState('');

    const filteredLegacyItems = activeLegacy.filter((item) => // Filter by the "active" lists since that's what we are actually displaying as the "shell"
        item.name.toLowerCase().includes(legacySearchQuery.toLowerCase())
    );

    const [modernSearchQuery, setModernSearchQuery] = React.useState('');

    const filteredModernItems = activeModern.filter((item) =>
        item.name.toLowerCase().includes(modernSearchQuery.toLowerCase())
    );
    return (
        <>
            <div className='overallContainer'>
                <div className='innerContainer'>
                    <div className='dropDown'>
                        {props.mode == 'update' ? (
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Transformations</InputLabel>
                                <Select
                                    label="Transformation"
                                    onChange={handleTransformationChange}
                                    value={transformation}
                                >
                                    {transformations.map((transformation) => (
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        <MenuItem
                                            key={transformation.id}
                                            value={transformation}
                                        >
                                            <ListItemText primary={`Transformation ${transformation.id}`} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>) :
                            (
                                <></>
                            )}
                    </div>
                    <div className='topContainer'>
                        <div className='legacyContainer'>
                            <div className='legacyLabel'>
                                <Typography variant="h6" style={{ fontSize: '15px' }}>Source</Typography>
                            </div>
                            <div className='legacyList'>
                                <TextField
                                    hiddenLabel
                                    id="filled-hidden-label-small"
                                    type="search"
                                    placeholder="Search"
                                    value={legacySearchQuery}
                                    onChange={(e) => setLegacySearchQuery(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    inputProps={{
                                        style: { fontSize: '12px' }
                                    }}
                                />
                                <FormGroup>
                                    {viewOnly ? props.selectedLegacy.map(column => {
                                        return (
                                            <Box key={column.id} sx={{ width: '100%' }}>
                                                <FormControlLabel control={<Checkbox
                                                    disabled={true}
                                                    onChange={() => handleToggle(column, true)}
                                                    checked={selectedLegacy.some(current => current.id === column.id)} // We do selectedLegacy here instead of activeLegacy since we only want what's actually checked in selectedLegacy, activeLegacy everything is there since it's just a shell
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fill: '#213353', // Set your desired color for the checkmark here
                                                        }
                                                    }}
                                                />} label={column.name} />
                                            </Box>
                                        );
                                    }) : filteredLegacyItems.map((column) => {
                                        return (
                                            <Box key={column.id} sx={{ width: '100%' }}>
                                                <FormControlLabel control={<Checkbox
                                                    onChange={() => handleToggle(column, true)}
                                                    checked={selectedLegacy.some(current => current.id === column.id)} // We do selectedLegacy here instead of activeLegacy since we only want what's actually checked in selectedLegacy, activeLegacy everything is there since it's just a shell
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fill: '#213353', // Set your desired color for the checkmark here
                                                        }
                                                    }}
                                                />} label={column.name} />
                                            </Box>
                                        );
                                    })}
                                </FormGroup>
                            </div>
                        </div>
                        <div className='modernContainer'>
                            <div className='modernLabel'>
                                <Typography variant="h6" style={{ fontSize: '15px' }}>Destination</Typography>
                            </div>
                            <div className='modernList'>
                                <TextField
                                    hiddenLabel
                                    id="filled-hidden-label-small"
                                    type="search"
                                    placeholder="Search"
                                    value={modernSearchQuery}
                                    onChange={(e) => setModernSearchQuery(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    inputProps={{
                                        style: { fontSize: '12px' }
                                    }}
                                />
                                <FormGroup>
                                    {viewOnly ? props.selectedModern.map(column => {
                                        return (
                                            <Box key={column.id} sx={{ width: '100%' }}>
                                                <FormControlLabel control={<Checkbox
                                                    disabled={true}
                                                    onChange={() => handleToggle(column, false)}
                                                    checked={selectedModern.some(current => current.id === column.id)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fill: '#213353', // Set your desired color for the checkmark here
                                                        }
                                                    }}
                                                />} label={column.name} />
                                            </Box>
                                        );
                                    }) : (
                                        filteredModernItems.map((column) => {
                                            // const labelId = `checkbox-list-secondary-label-${column.id}`;   // assigned a value but never used
                                            return (
                                                <Box key={column.id} sx={{ width: '100%' }}>
                                                    <FormControlLabel control={<Checkbox
                                                        onChange={() => handleToggle(column, false)}
                                                        checked={selectedModern.some(current => current.id === column.id)}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fill: '#213353', // Set your desired color for the checkmark here
                                                            }
                                                        }}
                                                    />} label={column.name} />
                                                </Box>
                                            );
                                        }))}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="bottomContainer">
                        <div className="descriptionContainer">
                            <div className='descriptionLabel'>
                                <Typography variant="h6" style={{ fontSize: '15px' }}>Description</Typography>
                            </div>
                            <div className='descriptionBox'>
                                <TextField
                                    hiddenLabel
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    placeholder="Description Placeholder"
                                    fullWidth // Make it take up the entire component width
                                    inputProps={{
                                        style: { fontSize: '12px', lineHeight: '1.5', backgroundColor: 'transparent' }
                                    }}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    disabled={viewOnly}
                                />
                            </div>
                        </div>
                        <div className='codeContainer'>
                            <div className='codeLabel'>
                                <Typography variant="h6" style={{ fontSize: '15px' }}>Code</Typography>
                            </div>
                            <div className='codeBox'>
                                <TextField
                                    hiddenLabel
                                    disabled
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    placeholder="Placeholder"
                                    fullWidth // Make it take up the entire component width
                                    inputProps={{
                                        style: { fontSize: '12px', lineHeight: '1.5', backgroundColor: 'transparent' }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttons">

                        {viewOnly ? (
                            <Button onClick={props.editMappingsButton} disabled={transformation && transformation.id === undefined}>
                                Edit Mappings
                            </Button>
                        ) : props.createMode ? (
                            <Button onClick={() => onCreate()}
                                disabled={selectedLegacy.length === 0 || selectedModern.length === 0}
                            >Create</Button>
                        ) :
                            (
                                <>
                                    <Button onClick={() => onSave()}
                                        //save button should be disabled if no columns are selected
                                        disabled={selectedLegacy.length === 0 || selectedModern.length === 0}
                                    >Save</Button>
                                    <Button onClick={() => onCancel()}>Cancel</Button>
                                </>
                            )}

                        {props.mode == 'update' ? (
                            <Button onClick={() => onDeleteTransformation()}>
                                Delete Transformation</Button>
                        ) : (
                            <></>
                        )}

                    </div>
                </div>
            </div>
        </>
    );
}

export default TransformationDetail;