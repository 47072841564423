import * as React from 'react';
import { TableMeta, SchemaMeta } from '../../@types';
import { useNavigate, useParams } from 'react-router-dom';
import {
    createSchemaMeta, deleteSchemaMeta,
    getSchemaMeta,
    updateSchemaMeta
} from '../../service/Meta/schemaMeta';

import {
    createTableMeta, deleteTableMeta,
    getAllBySchemaId,
    updateTableMeta
} from '../../service/Meta/tableMeta';
import MetaDetail, { Meta } from '../../common/MetaDetail/MetaDetail';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ParentIdState } from '../../store/parentIdSlice';

interface RouteParams extends Record<string, string> {
    schemaId: string; // new or id
}

const SchemaMetaDetail = () => {
    const navigate = useNavigate();
    const { id: parentId } = useSelector<RootState, ParentIdState>(
        (store) => store.parentId
    );

    const [children, setChildren] = React.useState<Meta[]>([])
    const initialValues: SchemaMeta = {
        id: undefined,
        name: '',
        description: '',
        databaseId: 0,
    }
    const [data, setData] = React.useState<SchemaMeta>(initialValues)

    const onCreate = async () => {
        try {
            const res = await createSchemaMeta({
                ...data, databaseId: parentId ?? 0,
            });
            navigate(`../schema/${res.data.id}`);
        } catch (error) {
            console.error('Error while creating:', error);
        }
    }

    const onDelete = () => {
        try {
            const temp = data.databaseId
            deleteSchemaMeta(Number(metaId));
            navigate(`../database/${temp}`)
        } catch (error) {
            console.error('error during deletion')
        }
    }

    const onUpdate = async () => {
        //console.log('update')
        try {
            const res = await updateSchemaMeta({
                id: metaId,
                name: data.name,
                description: data.description,
                databaseId: data.databaseId,
                tagIds: data.tagIds
            });
            navigate(`../schema/${res.data.id}`);
        } catch (error) {
            console.error('An error occurred while updating. Please try again.');
        }
    }

    const onCreateChild = async (child: Meta) => {
        // console.log(child);
        const tableChild: TableMeta = child as TableMeta;
        tableChild.id = undefined;
        tableChild.schemaId = Number(metaId);
        try {
            await createTableMeta(tableChild);
            //console.log(res);
            //TODO maybe this should not refetch all the data?
            getAllBySchemaId(Number(metaId)).then((res) => {
                const tables: TableMeta[] = res.data;
                setChildren(tables as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while creating child database')
        }
    }

    const onUpdateChild = async (child: Meta) => {
        try {
            await updateTableMeta(child as TableMeta);
            //console.log(res);
            getAllBySchemaId(Number(metaId)).then((res) => {
                const tables: TableMeta[] = res.data;
                setChildren(tables as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while updating child table')
        }
    }

    const onDeleteChild = async (childId: number) => {
        try {

            await deleteTableMeta(Number(childId));
            getAllBySchemaId(Number(metaId)).then((res) => {
                const tables: TableMeta[] = res.data;
                setChildren(tables as Meta[]);
            })
        } catch (error) {
            console.error('An error occurred while deleting child database')
        }
    }

    //get the id of the current project from the url
    const { schemaId } = useParams<RouteParams>();
    //This will become a state that allows the data prop to be populated
    const [metaId, setMetaId] = React.useState<number | undefined>(undefined);



    React.useEffect(() => {
        if (schemaId === 'new') {
            setMetaId(undefined);
            setChildren([]);
            setData(initialValues);
        }
        else {
            setMetaId(Number(schemaId));
        }
    }, [schemaId])

    //Populate the page with the specified project information
    React.useEffect(() => {
        if (metaId === undefined) {
            setData(initialValues)
            setChildren([]);
        }
        else {
            getSchemaMeta(Number(metaId)).then((res) => {
                setData(res.data)
            })
                .catch(err => console.error(err))
            getAllBySchemaId(Number(metaId)).then((res) => {
                const tables: TableMeta[] = res.data;
                const rowData: Meta[] = [];
                for (let i = 0; i < tables.length; i++) {
                    const curr: TableMeta = tables[i];
                    rowData.push(curr as Meta);
                }
                setChildren(rowData)
            })
                .catch(err => console.error(err))
        }

    }, [metaId]);


    return (
        <div>

            {/* eslint-disable-next-line react/no-children-prop */}
            <MetaDetail id={String(schemaId)} level="schema"
                onCreate={onCreate} onDelete={onDelete} setData={setData as React.Dispatch<React.SetStateAction<Meta>>} data={data as Meta} onUpdate={onUpdate} childrenData={children} updateChild={onUpdateChild}
                deleteChild={onDeleteChild}
                createChild={onCreateChild} />

        </div>
    );
};

export default SchemaMetaDetail;
