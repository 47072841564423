import axios, { AxiosInstance } from 'axios';

/**
 * If the application is for multiple customers based on URL,
 * use the first block for serverIpAddress.
 * Otherwise, use the second block.
 */

/* eslint-disable-next-line no-undef*/
/* Use this for one single customer URL*/
const serverIpAddress: string = process.env.REACT_APP_SERVICE_URL
    ? 'https://' + process.env.REACT_APP_SERVICE_URL
    : 'http://localhost:8080';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: serverIpAddress
});

export default axiosInstance;
