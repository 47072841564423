import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { TableHeader, Role } from '../../../@types';
import { getRolePage } from '../../../service/Management/roles';
import PageableList from '../../../common/PageableList/PageableList';

const headers: TableHeader[] = [
    { type: 'id', displayValue: 'ID' },
    { type: 'name', displayValue: 'Name' },
    { type: 'description', displayValue: 'Description' }
];

const RoleList: React.FC = () => {
    const navigate = useNavigate();

    const onSelectRole = (role: Role) => {
        navigate(`/role/${role.id}`);
    };

    const onAddRole = () => {
        navigate('/role/new');
    };

    return (
        <div className='page-body max-lg full-size'>
            <Card className='user-header'>
                <Typography variant='h4'>Roles</Typography>
                <Button
                    onClick={onAddRole}
                    startIcon={<AddIcon />}
                    variant='contained'
                >
                    Add Role
                </Button>
            </Card>
            <Card>
                <PageableList
                    initialOrderBy={'id'}
                    tableHeaders={headers}
                    getObjectPage={getRolePage}
                    onRowClick={onSelectRole}
                />
            </Card>
        </div>

    );
};

export default RoleList;
