import * as React from 'react';
import MetaDetail, { Meta } from '../../common/MetaDetail/MetaDetail';
import { useNavigate, useParams } from 'react-router-dom';
import {  getColumnMeta } from '../../service/Meta/columnMeta';
import { createColumnMeta, deleteColumnMeta, updateColumnMeta } from '../../service/Meta/columnMeta';
import { ColumnMeta } from '../../@types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ParentIdState } from '../../store/parentIdSlice';


const initialDataState : ColumnMeta = {
    id: undefined,
    name: '',
    description: '',
    tableId: 0,
    dataType: '',
    columnLength: 0
}

interface RouteParams extends Record<string, string> { columnId: string; }

const ColumnMetaDetail = () => {

    const navigate = useNavigate();
    const { id: parentId } = useSelector<RootState, ParentIdState>(
        (store) => store.parentId
    );

    const [data, setData] = React.useState<Meta>(initialDataState)

    const onCreate = async () => {

        try {
            const res = await createColumnMeta({ name: data.name, description: data.description, tableId: parentId ?? 0, dataType: (data as ColumnMeta).dataType, columnLength: (data as ColumnMeta).columnLength });

            setMetaId(res.data.id);
            navigate(`../column/${res.data.id}`);

        } catch (error) {
            console.error('Error while creating:', error);
        }
    }

    const onDelete = () => {
        try {
            deleteColumnMeta(Number(metaId));
            //navigate to parent
            navigate(`../table/${(data as ColumnMeta).tableId}`);
        } catch (error) {
            console.error('error during deletion')
        }

    }

    const onUpdate = async () => {
        //console.log('update')
        try {


            const res = await updateColumnMeta(data as ColumnMeta);

            setMetaId(res.data.id);
            navigate(`../column/${res.data.id}`);

        } catch (error) {
            console.error('Error while updating: ', error)
        }
    }

    /**
     * Adds a child to the editable list of children
     * Page rerenders including new child
     * @param child the child to add
     */
    const onCreateChild = async (child: Meta) => {
        console.log(child);

    }


    const onUpdateChild = async (child: Meta) => {
        console.log(child);

    }

    const onDeleteChild = async (childId: number) => {
        console.log(childId);
    }


    const { columnId } = useParams<RouteParams>();
    //This will become a state that allows the data prop to be populated
    const [metaId, setMetaId] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (columnId === 'new') {
            setMetaId(undefined);
            setData(initialDataState);
        }
        else {
            setMetaId(Number(columnId));
        }
    }, [columnId])

    React.useEffect(() => {
        if (metaId === undefined) {
            setData(initialDataState);
        } else {
            getColumnMeta(Number(metaId)).then((res) => {
                setData(res.data);
            })
        }

    }, [metaId]);


    return (
        <div>
            {<MetaDetail id={String(columnId)} level="column" onCreate={onCreate} onDelete={onDelete} setData={setData} data={data} onUpdate={onUpdate} childrenData={[]} createChild={onCreateChild} updateChild={onUpdateChild} deleteChild={onDeleteChild} />}

        </div>
    );


}

export default ColumnMetaDetail;