import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../@types';

export interface LoginState {
    user: User;
    permissions: string[];
    accessToken: string;
}
  
export const initialState: LoginState = {
    user: {
        id: undefined,
        firstName: '',
        lastName: '',
        email: '',
        password: undefined,
        roleIds: []
    },
    permissions: [],
    accessToken: ''
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<LoginState>) => {
            state.accessToken = action.payload.accessToken;
            state.user = action.payload.user;
            state.permissions = action.payload.permissions;
        },
        clearStoreLogout: () => {
            // Intentionally empty as we're clearing the state in store.ts
        },
        refreshAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        }
    },
})

export const { 
    loginSuccess, clearStoreLogout, refreshAccessToken
} = loginSlice.actions;

export default loginSlice.reducer;
