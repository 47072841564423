import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SaveIcon from '@mui/icons-material/Save';
import {
    BButton,
    BConfirmButton,
    BError,
    BForm,
    BFormik,
    BGrid,
    BSubmit,
    BT,
    BTextField
} from 'mui-bueno';

import { Role } from '../../../@types';
import { handleErrorResponse } from '../../../service/utils';
import {
    getRole,
    updateRole,
    createRole,
    deleteRole
} from '../../../service/Management/roles';
import { showSuccessSnackbar } from '../../../store/messageSnackbarSlice';

const schema = yup.object<Role>().shape({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required')
});

interface RouteParams extends Record<string, string> {
    roleId: string; // new or id
}
const RoleDetail: React.FC = () => {
    const { roleId } = useParams<RouteParams>();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [role, setRole] = React.useState<Role>({
        id: undefined,
        name: '',
        description: '',
        permissionIds: []
    });

    React.useEffect(() => {
        if (roleId === 'new') return;

        getRole(Number(roleId))
            .then((res) => {
                setRole(res.data);
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Role: '
                });
            });
    }, [roleId]);

    const handleSubmit = (data: Role, { setErrors }: FormikHelpers<Role>) => {
        if (data.id) {
            updateRole(data)
                .then((res) => {
                    setRole(res.data);
                    dispatch(showSuccessSnackbar(`${res.data.name} updated`));
                })
                .catch((err) => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not update Role: '
                    });
                });
        } else {
            createRole(data)
                .then((res) => {
                    navigate(`/role/${res.data.id}`);
                    dispatch(showSuccessSnackbar(`${res.data.name} created`));
                })
                .catch((err) => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not update create Role: '
                    });
                });
        }
    };

    const handleConfirmDelete = () => {
        if (!role.id) return;
        deleteRole(role.id)
            .then(() => {
                dispatch(showSuccessSnackbar(`${role.name} deleted`));
                navigate('/role');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not delete Role: '
                });
            });
    };

    return (
        <BFormik
            initialValues={role}
            onSubmit={handleSubmit}
            validationSchema={schema}
            enableReinitialize
        >
            <div className='page'>
                <div className='page-body max-md full-size'>
                    <BButton
                        variant='text'
                        onClick={() => navigate('/role')}
                        startIcon={<KeyboardBackspaceIcon />}
                        style={{ margin: '15px 0px 10px' }}
                    >
                        Back
                    </BButton>
                    <BForm>
                        <Card className='management-form'>
                            <CardHeader
                                title={
                                    <BT variant='h4' align='center'>
                                        {role.id ? `${role.name}` : 'Add Role'}
                                        <BError id='general' name='general' />
                                    </BT>
                                }
                            />
                            <CardContent>
                                <BGrid container spacing={1} alignment='center'>
                                    <BTextField
                                        name='name'
                                        variant='filled'
                                        xs={12}
                                        noMP
                                    />
                                    <BTextField
                                        name='description'
                                        multiline
                                        variant='filled'
                                        xs={12}
                                        noMP
                                    />
                                </BGrid>
                            </CardContent>
                            <CardActions>
                                {role.id ? (
                                    <BConfirmButton
                                        objectType='Role'
                                        objectName={`${role.name}`}
                                        onConfirm={handleConfirmDelete}
                                    />
                                ) : (
                                    <div />
                                )}
                                <BSubmit startIcon={<SaveIcon />}>Save</BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </div>
            </div>
        </BFormik>
    );
};

export default RoleDetail;
