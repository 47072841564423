import { CloneProjectRequest, PagingRequest, PagingResponse } from '../../@types';
import { ProjectMeta } from '../../@types';
import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';

/**
 * Clone project TOO BE CHANGED BY VIVEK AND BRENNAN
 */
export const cloneProjectMeta = (cloneProjectRequest: CloneProjectRequest) =>
    axiosInstance
        .post('/api/users/clone-project', cloneProjectRequest)
        .catch(handleError);


export const createProjectMeta = (projectMeta: ProjectMeta) =>
    axiosInstance
        .post<ProjectMeta>('/api/project', projectMeta)
        .catch(handleError);

export const updateProjectMeta = (projectMeta: ProjectMeta) =>
    axiosInstance
        .put<ProjectMeta>('/api/project', projectMeta)
        .catch(handleError);

export const getProjectMeta = (id: number) =>
    axiosInstance
        .get<ProjectMeta>(`/api/project/${id}`)
        .catch(handleError);


export const deleteProjectMeta = (id: number) =>
    axiosInstance
        .delete(`/api/project/${id}`)
        .catch(handleError);

// export const getProjectPage = (page: number, size: number, sortFld: string) =>
export const getProjectPage = (pageReq: PagingRequest) => {
    return axiosInstance
        .get<PagingResponse<ProjectMeta>>(`/api/project/page?page=${pageReq.page}&size=${pageReq.size}&sortFld=${pageReq.sort}`)
        .catch(handleError);
}

export const getAllProjects = () => {
    return axiosInstance.get<ProjectMeta[]>('/api/project/all')
        .catch(handleError);
}


