import * as React from 'react';
import { AppBar, Typography } from '@mui/material';

const EnvHeader: React.FC = () => {

    const envMessage: string | null = process.env.REACT_APP_ENV_MESSAGE
        ? process.env.REACT_APP_ENV_MESSAGE.toUpperCase()
        : null;

    return (
        envMessage
            ? <AppBar position='sticky' elevation={1} color='default' data-testid='env-header'>
                <Typography color='error' variant='h3' className='header-text'>
                    {envMessage}
                </Typography>
            </AppBar>
            : null
    );
};

export default EnvHeader;
