import * as React from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';

import useLoggedInUser from '../hooks/useLoggedInUser';
import { handleTimeout, handleTimeoutWarning } from './IdleTimeoutUtil';

const IdleTimeout: React.FC = () => {
    const timeoutAt = 1000 * 60 * 30; // 30 mins
    const warningInterval = 1000 * 60 * 1; // 1 mins

    const [isTimeout, setIsTimeout] = React.useState(false);
    const [isWarning1, setIsWarning1] = React.useState(false);
    const [isWarning2, setIsWarning2] = React.useState(false);
    const [isWarning3, setIsWarning3] = React.useState(false);
    const [isWarning4, setIsWarning4] = React.useState(false);
    const [isWarning5, setIsWarning5] = React.useState(false);

    const handleOnActive = () => {
        setIsTimeout(false);
        setIsWarning1(false);
        setIsWarning2(false);
        setIsWarning3(false);
        setIsWarning4(false);
        setIsWarning5(false);
    };

    useIdleTimer({
        timeout: timeoutAt,
        onActive: handleOnActive,
        onIdle: () => setIsTimeout(true)
    });

    useIdleTimer({
        timeout: timeoutAt - 1 * warningInterval,
        onActive: handleOnActive,
        onIdle: () => setIsWarning1(true)
    });
    useIdleTimer({
        timeout: timeoutAt - 2 * warningInterval,
        onActive: handleOnActive,
        onIdle: () => setIsWarning2(true)
    });
    useIdleTimer({
        timeout: timeoutAt - 3 * warningInterval,
        onActive: handleOnActive,
        onIdle: () => setIsWarning3(true)
    });
    useIdleTimer({
        timeout: timeoutAt - 4 * warningInterval,
        onActive: handleOnActive,
        onIdle: () => setIsWarning4(true)
    });
    useIdleTimer({
        timeout: timeoutAt - 5 * warningInterval,
        onActive: handleOnActive,
        onIdle: () => setIsWarning5(true)
    });

    const dispatch = useDispatch();
    const user = useLoggedInUser();

    React.useEffect(() => {
        if (!user.id) return;

        handleTimeout(dispatch, isTimeout) ||
            handleTimeoutWarning(
                dispatch,
                isWarning1,
                setIsWarning1,
                1 * warningInterval
            ) ||
            handleTimeoutWarning(
                dispatch,
                isWarning2,
                setIsWarning2,
                2 * warningInterval
            ) ||
            handleTimeoutWarning(
                dispatch,
                isWarning3,
                setIsWarning3,
                3 * warningInterval
            ) ||
            handleTimeoutWarning(
                dispatch,
                isWarning4,
                setIsWarning4,
                4 * warningInterval
            ) ||
            handleTimeoutWarning(
                dispatch,
                isWarning5,
                setIsWarning5,
                5 * warningInterval
            );
    }, [
        isTimeout,
        user.id,
        isWarning1,
        isWarning2,
        isWarning3,
        isWarning4,
        isWarning5
    ]);

    // Intentionally has null return because we don't need to render any TSX
    return null;
};

export default IdleTimeout;
