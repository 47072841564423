import axiosInstance from '../Access/axiosInstance';
import { Tag, PagingResponse, TagPageRequest } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of tags
 */
export const getTagPage = (pageReq: TagPageRequest) =>
    axiosInstance
        .get<PagingResponse<Tag>>(`/api/tag/page?page=${pageReq.page}&size=${pageReq.size}&sortFld=${pageReq.sort}`).catch(handleError);

/**
 * Gets a tag by id
 */
export const getTag = (id: number) =>
    axiosInstance.get<Tag>(`/api/tag/${id}`).catch(handleError);

/**
 * Updates a tag
 */
export const updateTag = (tag: Tag) =>
    axiosInstance.put<Tag>('/api/tag', tag).catch(handleError);

/**
 * Creates a tag
 */
export const createTag = (tag: Tag) =>
    axiosInstance.post<Tag>('/api/tag', tag).catch(handleError);

/**
 * Deletes a tag
 */
export const deleteTag = (id: number) =>
    axiosInstance.delete(`/api/tag/${id}`).catch(handleError);

export const getAllTags = () => {
    return axiosInstance
        .get<Tag[]>('/api/tag/all')
        .catch(handleError); // Changed
}

/**
 * adds a tag to a level component
 * 
 * @param tagId id of tag to add to level
 * @param Id id of level component
 * @param level HAS TO BE LOWERCASE 
 * @returns 
 */
export const addTagAssociation = (tagId: number, id: number, level: string) => {
    return axiosInstance.put<Tag>(`/api/tag/${tagId}/add/${level}/${id}`).catch(handleError);
}

/**
 * removes a tag associated with a level component
 * 
 * @param tagId id of tag to add to level
 * @param Id id of level component
 * @param level HAS TO BE LOWERCASE 
 * @returns 
 */
export const deleteTagAssociation = (tagId: number, id: number, level: string) => {
    return axiosInstance.put<Tag>(`/api/tag/${tagId}/delete/${level}/${id}`).catch(handleError);
}