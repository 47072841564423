import * as React from 'react';
import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import { BButton } from 'mui-bueno';
import { useNavigate } from 'react-router-dom';

interface Props {
    handleDrawerToggle: () => void;
}

const LoggedOut: React.FC<Props> = (props) => {
    const { handleDrawerToggle } = props;

    const navigate = useNavigate();

    return (
        <>
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                <List
                    component='div'
                    onClick={handleDrawerToggle}
                    disablePadding
                >
                    <ListItemButton
                        onClick={() => {
                            navigate('/login');
                        }}
                    >
                        <ListItemText
                            primary='Login'
                            onClick={() => {
                                navigate('/login');
                            }}
                            className='drawer-link'
                        />
                    </ListItemButton>
                </List>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <BButton
                    onClick={() => {
                        navigate('/login');
                    }}
                    disableRipple
                    className='button-outline'
                >
                    Login
                </BButton>
            </Box>
        </>
    );
};

export default LoggedOut;