import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { User, TableHeader } from '../../../@types';
import { getUserPage } from '../../../service/Management/users';
import PageableList from '../../../common/PageableList/PageableList';

const headers: TableHeader[] = [
    { type: 'id', displayValue: 'ID' },
    { type: 'firstName', displayValue: 'First Name' },
    { type: 'lastName', displayValue: 'Last Name' },
    { type: 'email', displayValue: 'Email' },
    { type: 'roleIds', displayValue: 'Roles' }
];

const UserList: React.FC = () => {
    const navigate = useNavigate();

    const onSelectUser = (user: User) => {
        navigate(`/user/${user.id}`);
    };

    const onAddUser = () => {
        navigate('/user/new');
    };

    return (
        <div className='page-body max-lg full-size'>
            <Card className='user-header'>
                <Typography variant='h4'>Users</Typography>
                <Button
                    onClick={onAddUser}
                    startIcon={<AddIcon />}
                    variant='contained'
                >
                    Add User
                </Button>
            </Card>
            <Card>
                <PageableList
                    initialOrderBy={'id'}
                    tableHeaders={headers}
                    getObjectPage={getUserPage}
                    onRowClick={onSelectUser}
                />
            </Card>
        </div>

    );
};

export default UserList;
