import { Dispatch } from 'react';
import { clearStoreLogout } from '../../store/loginSlice';
import { showGeneralSnackbar } from '../../store/messageSnackbarSlice';
import { logout } from '../../service/Access/authentication';
import axiosInstance from '../../service/Access/axiosInstance';

export const logoutBehavior = (dispatch: Dispatch<any>) => {
    axiosInstance.defaults.headers.common.Authorization = '';
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    dispatch(clearStoreLogout());
    dispatch(
        showGeneralSnackbar(
            'Your session has timed out due to inactivity. Please login again.'
        )
    );
};

export const showWarningMessage = (
    milliSeconds: number,
    dispatch: Dispatch<any>
) => {
    const min = milliSeconds / (1000 * 60);
    const unit = min > 1 ? 'minutes' : 'minute';
    dispatch(
        showGeneralSnackbar(
            `You will be logged out in ${min} ${unit} due to inactivity. Perform any action to extend your session.`
        )
    );
};

export const handleTimeout = (
    dispatch: Dispatch<any>,
    isTimeout: boolean
): boolean => {
    if (isTimeout) {
        const refreshToken = localStorage.getItem('refreshToken');

        logout(refreshToken)
            .then(() => logoutBehavior(dispatch))
            .catch(() => logoutBehavior(dispatch));
    }
    return isTimeout;
};

export const handleTimeoutWarning = (
    dispatch: Dispatch<any>,
    isWarning: boolean,
    setWarning: any,
    remainingMilliSeconds: number
): boolean => {
    if (isWarning) {
        showWarningMessage(remainingMilliSeconds, dispatch);
        setWarning(false);
    }
    return isWarning;
};
