import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getSchemaMeta } from '../../service/Meta/schemaMeta';
import { getDatabaseMeta } from '../../service/Meta/databaseMeta';
import { getProjectMeta } from '../../service/Meta/projectMeta';
import { getTableMeta } from '../../service/Meta/tableMeta';
import { Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { AccountTree as AccountTreeIcon, Schema as SchemaIcon, Storage as StorageIcon, TableView as TableViewIcon } from '@mui/icons-material';
import { getColumnMeta } from '../../service/Meta/columnMeta';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

interface BreadcrumbProps {
    type: 'project' | 'database' | 'schema' | 'table' | 'column';
    id?: number;
}

interface EntityMeta {
    id?: number;
    name: string;
    description: string;
    parentId: number | null;
    type: string;
}

interface ParentIdState {
    id: number | null;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ type, id }) => {
    const [crumbs, setCrumbs] = useState<Array<EntityMeta>>([]);
    const parentIdFromState = useSelector<RootState, ParentIdState>(
        (store) => store.parentId.id
    );

    useEffect(() => {

        const loadColumnMeta = async (id: number) => {
            const response = await getColumnMeta(id);
            const columnMeta = response.data;
            return {id: columnMeta.id, name: columnMeta.name, description: columnMeta.description, parentId : columnMeta.tableId}
        }

        const loadTableMeta = async (id: number) => {
            const response = await getTableMeta(id);
            const tableMeta = response.data;
            return { id: tableMeta.id, name: tableMeta.name, description: tableMeta.description, parentId: tableMeta.schemaId };
        };

        const loadSchemaMeta = async (id: number) => {
            const response = await getSchemaMeta(id);
            const schemaMeta = response.data;
            return { id: schemaMeta.id, name: schemaMeta.name, description: schemaMeta.description, parentId: schemaMeta.databaseId };
        };

        const loadDatabaseMeta = async (id: number) => {
            const response = await getDatabaseMeta(id);
            const databaseMeta = response.data;
            return { id: databaseMeta.id, name: databaseMeta.name, description: databaseMeta.description, parentId: databaseMeta.projectId };
        };

        const loadProjectMeta = async (id: number) => {
            const response = await getProjectMeta(id);
            const projectMeta = response.data;
            return { id: projectMeta.id, name: projectMeta.name, description: projectMeta.description, parentId: null };
        };

        const loadCrumbs = async () => {
            const crumbsArray: Array<EntityMeta> = [];

            let isNew = false;
            if (!id) {
                crumbsArray.push({ id: undefined, name: 'new', description: '', parentId: parentIdFromState as unknown as number, type: type });
                id = parentIdFromState as unknown as number;
                isNew = true;
            }
            let parentId = id;
            if (!parentId) return;


            // GET ALL PARENT DATA UNDER ONE IF STATEMENT
            if (type === 'column') {
                let columnMeta;
                if (!isNew) {
                    columnMeta = await loadColumnMeta(parentId);
                    const tempColumnMeta = { type: 'column', ...columnMeta };
                    crumbsArray.push(tempColumnMeta);
                    parentId = columnMeta.parentId;
                }
            
                const tableMeta = await loadTableMeta(parentId);
                const tempTableMeta = { type: 'table', ...tableMeta }
                crumbsArray.push(tempTableMeta);
                parentId = tableMeta.parentId;
            
                const schemaMeta = await loadSchemaMeta(parentId);
                const tempSchemaMeta = { type: 'schema', ...schemaMeta };
                crumbsArray.push(tempSchemaMeta);
                parentId = schemaMeta.parentId;
            
                const databaseMeta = await loadDatabaseMeta(parentId);
                const tempDatabaseMeta = { type: 'database', ...databaseMeta };
                crumbsArray.push(tempDatabaseMeta);
                parentId = databaseMeta.parentId;
            
                const projectMeta = await loadProjectMeta(parentId);
                const tempProjectMeta = { type: 'project', ...projectMeta }
                crumbsArray.push(tempProjectMeta);
            }
            

            if (type === 'table') {
                if (!isNew) {
                    const tableMeta = await loadTableMeta(parentId);
                    const tempTableMeta = { type: 'table', ...tableMeta }
                    crumbsArray.push(tempTableMeta);
                    parentId = tableMeta.parentId;
                }

                const schemaMeta = await loadSchemaMeta(parentId);
                const tempSchemaMeta = { type: 'schema', ...schemaMeta };
                crumbsArray.push(tempSchemaMeta);
                parentId = schemaMeta.parentId;

                const databaseMeta = await loadDatabaseMeta(parentId);
                const tempDatabaseMeta = { type: 'database', ...databaseMeta };
                crumbsArray.push(tempDatabaseMeta);
                parentId = databaseMeta.parentId;

                const projectMeta = await loadProjectMeta(parentId);
                const tempProjectMeta = { type: 'project', ...projectMeta }
                crumbsArray.push(tempProjectMeta);
            }

            if (type === 'schema') {
                if (!isNew) {
                    const schemaMeta = await loadSchemaMeta(parentId);
                    const tempSchemaMeta = { type: 'schema', ...schemaMeta };
                    crumbsArray.push(tempSchemaMeta);
                    parentId = schemaMeta.parentId;
                }

                const databaseMeta = await loadDatabaseMeta(parentId);
                const tempDatabaseMeta = { type: 'database', ...databaseMeta };
                crumbsArray.push(tempDatabaseMeta);
                parentId = databaseMeta.parentId;

                const projectMeta = await loadProjectMeta(parentId);
                const tempProjectMeta = { type: 'project', ...projectMeta }
                crumbsArray.push(tempProjectMeta);
            }

            if (type === 'database') {
                if (!isNew) {
                    const databaseMeta = await loadDatabaseMeta(parentId);
                    const tempDatabaseMeta = { type: 'database', ...databaseMeta };
                    crumbsArray.push(tempDatabaseMeta);
                    parentId = databaseMeta.parentId;
                }

                const projectMeta = await loadProjectMeta(parentId);
                const tempProjectMeta = { type: 'project', ...projectMeta }
                crumbsArray.push(tempProjectMeta);
            }

            if (type === 'project') {
                if (!isNew) {
                    const projectMeta = await loadProjectMeta(parentId);
                    const tempProjectMeta = { type: 'project', ...projectMeta }
                    crumbsArray.push(tempProjectMeta);
                }
            }

            setCrumbs(crumbsArray.reverse());
        };

        if (id || parentIdFromState) loadCrumbs();
    }, [id, type, parentIdFromState]);

    const getIconByType = (type: string) => {
        switch (type) {
            case 'project':
                return <AccountTreeIcon fontSize="inherit" />;
            case 'database':
                return <StorageIcon fontSize="inherit" />;
            case 'schema':
                return <SchemaIcon fontSize="inherit" />;
            case 'table':
                return <TableViewIcon fontSize="inherit" />;
            case 'column':
                return <ViewColumnIcon fontSize="inherit" />;
            default:
                return null;
        }
    };

    return (
        <div>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {crumbs.map((crumb) => (
                    <Link
                        style={{ color: '#101827', textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                        key={crumb.id}
                        to={`/${crumb.type}/${crumb.id ? crumb.id : 'new'}`}
                    >
                        <span style={{ marginRight: '5px' }}>{getIconByType(crumb.type)}</span>
                        <span style={{ position: 'relative', top: '-2px' }}>{crumb.name}</span>
                    </Link>
                ))}
            </Breadcrumbs>
        </div>
    );
};

export default Breadcrumb;
