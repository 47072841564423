import * as React from 'react';
import { AxiosResponse } from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';

import { TableHeader, UserPageRequest, RolePageRequest, PagingRequest, Tag } from '../../@types';
import { getAllTags } from '../../service/Management/tags';
import { useState } from 'react';

type PageRequest = UserPageRequest | RolePageRequest | PagingRequest;

interface Props {
    tableHeaders: TableHeader[];
    getObjectPage: (pageReq: PageRequest) => Promise<AxiosResponse<any>>;
    initialOrderBy?: string;
    onRowClick?: (obj: any) => void;
    additionalControls?: JSX.Element;
}

interface State {
    objects: any[];
    rowsPerPage: number;
    requestedPage: number;
    orderBy: string;
    totalElements: number;
    page: number;
}

const initialState: State = {
    objects: [],
    rowsPerPage: 10,
    requestedPage: 0,
    orderBy: 'id',
    totalElements: 0,
    page: 0
};

interface Action {
    type: 'objectPage' | 'requestedPage' | 'rowsPerPage' | 'orderBy';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
                totalElements: action.payload.total,
                page: action.payload.page
            };
        case 'requestedPage':
            return {
                ...state,
                requestedPage: action.payload
            };
        case 'rowsPerPage':
            return {
                ...state,
                rowsPerPage: action.payload,
                page: 0,
                requestedPage: 0
            };
        case 'orderBy':
            return {
                ...state,
                orderBy: action.payload
            };
        default:
            return state;
    }
};

const PageableList: React.FC<Props> = (props) => {
    const {
        tableHeaders,
        getObjectPage,
        onRowClick,
        initialOrderBy,
        additionalControls
    } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const {
        objects,
        rowsPerPage,
        requestedPage,
        orderBy,
        totalElements,
        page
    } = state;

    const emptyRows = rowsPerPage - objects.length;

    React.useEffect(() => {
        if (initialOrderBy) {
            dispatch({ type: 'orderBy', payload: initialOrderBy });
        }
    }, [initialOrderBy]);

    React.useEffect(() => {
        const pageReq: PageRequest = {
            page: requestedPage,
            size: rowsPerPage,
            sort: orderBy
        };

        getObjectPage(pageReq).then((res) => {
            dispatch({ type: 'objectPage', payload: res.data });
        });
    }, [getObjectPage, requestedPage, rowsPerPage, orderBy]);

    const handlePageChange = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        dispatch({ type: 'requestedPage', payload: newPage });
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        dispatch({
            type: 'rowsPerPage',
            payload: parseInt(event.target.value, 10)
        });
    };

    const handleChangeSortBy = (header: string) => () => {
        dispatch({ type: 'orderBy', payload: header });
    };

    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

    const getProjTags = (ids: number[]): Tag[] => {
        getAllTags().then(res => {
            setSelectedTags(res.data);
        }).catch(err => {
            console.error(err);
        });
        const tags: Tag[] = [];
        selectedTags.forEach(tag => {
            if (tag.id != undefined && ids.includes(tag.id)) {
                tags.push(tag);
            }
        })
        return tags;
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header) => {
                            return (
                                <TableCell
                                    key={`header-${header.type}`}
                                    onClick={handleChangeSortBy(header.type)}
                                    className={
                                        orderBy === header.type
                                            ? 'selected-header cursor-pointer'
                                            : 'cursor-pointer'
                                    }
                                >
                                    {header.displayValue}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {objects.map((obj: any, idx: number) => {
                        return (
                            <TableRow
                                key={`row-${idx}`}
                                onClick={onRowClick && (() => onRowClick(obj))}
                                className={onRowClick && 'cursor-pointer'}
                            >
                                {tableHeaders.map((header) => {
                                    if (header.type === 'tagIds') {
                                        console.log(obj[header.type]);
                                        const tags = getProjTags(obj[header.type]);
                                        console.log(tags);
                                        const tagNames = tags.map(tag => tag.name);
                                        const returnString = tagNames.join(', ');
                                        console.log(returnString);
                                        return (
                                            <TableCell
                                                key={`${header.type}-${idx}`}
                                            >
                                                {returnString}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell
                                                key={`${header.type}-${idx}`}
                                            >
                                                {obj[header.type]}
                                            </TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        );
                    })}
                    {totalElements > rowsPerPage && emptyRows > 0 && (
                        <TableRow style={{ height: 51 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    {objects.length === 0 && (
                        <TableRow>
                            <TableCell
                                align='center'
                                colSpan={tableHeaders.length}
                            >
                                <Typography variant='body1'>
                                    No data to display.
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <div className='control-bar'>
                {additionalControls}
                <TablePagination
                    component='div'
                    className='right'
                    count={totalElements}
                    page={page}
                    onPageChange={handlePageChange}
                    showFirstButton
                    showLastButton
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 15, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Items per page:'
                    sx={{ height: 'fit-content', overflow: 'hidden' }}
                />
            </div>
        </>
    );
};

export default PageableList;
